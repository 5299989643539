import s from './AutoTrade.module.scss'

import React, { useCallback } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl } from 'shared/components'
import { normalizeDataForSelectAndRadio } from 'shared/utils'
import { RithmicService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { RithmicStartDto } from 'api/http/models'
import { useFetch } from 'shared/hooks'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ marketData, onMarketFetch }) => {
        const [onStartFetch] = useFetch(RithmicService.start)
        const [onStopFetch] = useFetch(RithmicService.stop)

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: RithmicStartDto) => {
                if (marketData) {
                    if (marketData?.robotId) {
                        await onStopFetch(marketData.id)
                    } else {
                        await onStartFetch(marketData.id, body)
                    }
                    await onMarketFetch()
                }
            },
            [marketData, onMarketFetch]
        )

        return (
            <div>
                <div className={s.balance}>Min deposit size: 10,000 USD</div>

                <Form layout="vertical" onFinish={handleFinish}>
                    <Row gutter={10}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="symbol"
                                label="Symbol"
                                initialValue="MESU4"
                            >
                                <SelectControl
                                    disabled={!!marketData?.robotId}
                                    options={['MESU4', 'ESU4'].map(
                                        normalizeDataForSelectAndRadio
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Button
                                className={s.button}
                                type="primary"
                                htmlType="submit"
                                danger={!!marketData?.robotId}
                                disabled={!marketData || marketData.isBeStopped}
                            >
                                {marketData?.robotId ? 'STOP' : 'START'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
)
