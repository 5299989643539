import React from 'react'
import { OrdersProps } from './Orders.types'
import { TableAdapter } from 'shared/components'
import { ordersTableColumns } from './Orders.utils'
import { BinanceNativeService } from 'api/http'
import { message } from 'antd'

/** Таблица открытых ордеров */
export const Orders: React.FC<OrdersProps> = React.memo(
    ({ ordersData, robotId, marketId, symbol }) => {
        /**
         * Обработчик отмены ордера
         */
        const handleCancelOrder = async (id: number, symbol: string) => {
            try {
                if (marketId) {
                    const { orderId } = await BinanceNativeService.cancelOrder({
                        order_id: id,
                        symbol,
                        market_id: marketId,
                    })
                    if (orderId) message.success('Order cancelled')
                }
            } catch (e) {
                console.log(e)
            }
        }

        /**
         * Обработчик отмены всех ордеров
         */
        const handleCancelAllOrders = async () => {
            try {
                if (!ordersData?.length || !symbol || !marketId) return

                const { code } = await BinanceNativeService.cancelAllOrders({
                    symbol: symbol,
                    market_id: marketId,
                })
                if (Number(code) === 200) message.success('Orders cancelled')
            } catch (e) {
                console.log(e)
            }
        }

        return (
            <TableAdapter
                title={() => <h2>Orders</h2>}
                columns={ordersTableColumns(
                    handleCancelOrder,
                    handleCancelAllOrders,
                    robotId,
                    symbol,
                    ordersData?.length
                )}
                dataSource={ordersData?.filter(
                    el => Number(el.origQty) - Number(el.executedQty)
                )}
                loading={!ordersData}
                pagination={false}
                rowKey="orderId"
            />
        )
    }
)
