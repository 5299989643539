const translations = {
    en: {
        globalMarkets: 'GLOBAL MARKETS',
        ourFeatures: 'Our features:',
        feature1:
            'Connection via Rithmic datafeed to 19 global exchanges, including CME, EUREX, COMEX, NYMEX, SGX, ICE, etc.',
        feature2:
            "Any FCM customer, with a newly opened or existing account, can quickly and easily connect their trading account to RealColibri. <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Follow this link to see the list of FCMs (cftc.gov).</a>",
        feature3:
            'All profits remain in your trading account. We charge our commission for the use of the algorithm either in our RCBC token (which we recommend) or on individual contractual terms. Contact us to discuss the details.',
        feature4:
            'Only you control your trading account and the operation of the algorithm.',
        feature5:
            'Trading parameters are set to minimize risks for stable and safe trading.',
        feature6: 'More than 1,000 trades per day.',
        chooseExchange: 'Choose your exchange',
        requirementsTitle: 'Requirements to get started:',
        requirement1: 'RealColibri account',
        requirement2: 'TronLink wallet',
        requirement3: 'Trading account at FCM',
        requirement4:
            'According to Rithmic rules, to obtain a password, a minimum deposit of 10,000 USD is required.',
        requirement5: 'Minimum deposit for live automatic trading: 50,000 USD',
        requirement6Mesu3:
            'For Micro E-mini S&P500 (MES): minimum 250,000 USD, maximum 100,000,000 USD.',
        requirement6Esu3:
            'For E-mini S&P500 (ES): minimum 2,500,000 USD, maximum 1,000,000,000 USD.',
        automateTitle: 'How to automate your trading:',
        automateStep1: 'Register at RealColibri',
        automateStep2:
            'Open an account with an FCM which supports Rithmic datafeed (<a href="https://www.cftc.gov/MarketReports/financialfcmdata/index.htm" target="_blank" rel="noreferrer" className={s.guideLink}>the list of FCMs</a>)',
        automateStep3: 'Fund your FCM trading account',
        automateStep4:
            'Subscribe to Rithmic market data (we recommend CME Bundle by default)',
        automateStep5:
            'Get a login and password (demo or live) and connect them on the RealColibri Automated Trading page',
        automateStep6: 'Select a symbol and press START',
        exchangeTitle: 'EXCHANGE TRX FOR RCBC',
        exchangeStep1: 'Create a TronLink Wallet',
        exchangeStep2: 'Transfer TRX to it',
        exchangeStep3: 'Exchange TRX for RCBC using this form',
        exchangeStep4:
            'Top up the commission deposit in your RealColibri account',
        exchangeInfo:
            'Exchange TRX for RCBC to pay a commission for using the algorithm.',
        refreshInfo:
            'Refresh the page after installation. You will be prompted to connect your wallet to the website.',
        contactUs:
            'Feel free to reach us if you have any questions or need additional information:',
        contactEmail: 'mail@realcolibri.com',
    },
    ru: {
        globalMarkets: 'ГЛОБАЛЬНЫЕ РЫНКИ',
        ourFeatures: 'Наши особенности:',
        feature1:
            'Подключение через Rithmic datafeed к 19 глобальным биржам, включая CME, EUREX, COMEX, NYMEX, SGX, ICE и другие.',
        feature2:
            "Любой клиент FCM, с недавно открытым или существующим счетом, может быстро и легко подключить свой торговый счет к RealColibri. <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Следуйте по этой ссылке, чтобы увидеть список FCM (cftc.gov).</a>",
        feature3:
            'Вся прибыль остается на вашем торговом счете. Мы взимаем комиссию за использование алгоритма либо в нашем токене RCBC (что мы рекомендуем), либо на индивидуальных контрактных условиях. Свяжитесь с нами, чтобы обсудить детали.',
        feature4:
            'Только вы управляете своим торговым счетом и работой алгоритма.',
        feature5:
            'Торговые параметры установлены для минимизации рисков для стабильной и безопасной торговли.',
        feature6: 'Более 1,000 сделок в день.',
        chooseExchange: 'Выберите вашу биржу',
        requirementsTitle: 'Требования для начала:',
        requirement1: 'Аккаунт RealColibri',
        requirement2: 'Кошелек TronLink',
        requirement3: 'Торговый счет в FCM',
        requirement4:
            'По правилам Rithmic для получения пароля необходимо наличие минимум 10,000 USD на депозите.',
        requirement5:
            'Минимальный депозит для начала реальной автоматической торговли: 250,000 USD',
        requirement6Mesu3:
            'Для Micro E-mini S&P500 (MES): минимум 250,000 USD, максимум 100,000,000 USD.',
        requirement6Esu3:
            'Для E-mini S&P500 (ES): минимум 2,500,000 USD, максимум 1,000,000,000 USD.',
        automateTitle: 'Как автоматизировать вашу торговлю:',
        automateStep1: 'Зарегистрируйтесь на RealColibri',
        automateStep2:
            "Откройте счет в FCM, который поддерживает Rithmic datafeed (<a href=\"https://www.cftc.gov/MarketReports/financialfcmdata/index.htm\" target='_blank' rel='нoreferrer' className={s.guideLink}>список FCM</a>)",
        automateStep3: 'Пополните ваш торговый счет в FCM',
        automateStep4:
            'Подпишитесь на рыночные данные Rithmic (мы рекомендуем CME Bundle по умолчанию)',
        automateStep5:
            'Получите логин и пароль (демо или реальный) и подключите их на странице автоматической торговли RealColibri',
        automateStep6: 'Выберите символ и нажмите НАЧАТЬ',
        exchangeTitle: 'ОБМЕН TRX НА RCBC',
        exchangeStep1: 'Создайте кошелек TronLink',
        exchangeStep2: 'Переведите TRX на него',
        exchangeStep3: 'Обменяйте TRX на RCBC с помощью этой формы',
        exchangeStep4:
            'Пополните комиссионный депозит в вашем аккаунте RealColibri',
        exchangeInfo:
            'Обменяйте TRX на RCBC для оплаты комиссии за использование алгоритма.',
        refreshInfo:
            'Обновите страницу после установки. Вам будет предложено подключить ваш кошелек к сайту.',
        contactUs:
            'Свяжитесь с нами, если у вас есть вопросы или нужна дополнительная информация:',
        contactEmail: 'mail@realcolibri.com',
    },
    es: {
        globalMarkets: 'MERCADOS GLOBALES',
        ourFeatures: 'Nuestras características:',
        feature1:
            'Conexión a través de Rithmic datafeed a 19 bolsas globales, incluidas CME, EUREX, COMEX, NYMEX, SGX, ICE, etc.',
        feature2:
            "Cualquier cliente de FCM, con una cuenta nueva o existente, puede conectar rápida y fácilmente su cuenta de trading a RealColibri. <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Siga este enlace para ver la lista de FCMs (cftc.gov).</a>",
        feature3:
            'Todas las ganancias permanecen en su cuenta de trading. Cobramos nuestra comisión por el uso del algoritmo ya sea en nuestro token RCBC (que recomendamos) o en términos contractuales individuales. Contáctenos para discutir los detalles.',
        feature4:
            'Solo usted controla su cuenta de trading y el funcionamiento del algoritmo.',
        feature5:
            'Los parámetros de trading están configurados para minimizar los riesgos para un trading estable y seguro.',
        feature6: 'Más de 1,000 operaciones por día.',
        chooseExchange: 'Elija su intercambio',
        requirementsTitle: 'Requisitos para empezar:',
        requirement1: 'Cuenta de RealColibri',
        requirement2: 'Cartera TronLink',
        requirement3: 'Cuenta de trading en FCM',
        requirement4:
            'Según las reglas de Rithmic, para obtener una contraseña, se requiere un depósito mínimo de 10,000 USD.',
        requirement5:
            'Depósito mínimo para empezar a operar en vivo automáticamente: 50,000 USD',
        requirement6Mesu3:
            'Para Micro E-mini S&P500 (MES): mínimo 250,000 USD, máximo 100,000,000 USD.',
        requirement6Esu3:
            'Para E-mini S&P500 (ES): mínimo 2,500,000 USD, máximo 1,000,000,000 USD.',
        automateTitle: 'Cómo automatizar su trading:',
        automateStep1: 'Regístrese en RealColibri',
        automateStep2:
            "Abra una cuenta con un FCM que soporte Rithmic datafeed (<a href=\"https://www.cftc.gov/MarketReports/financialfcmdata/index.htm\" target='_blank' rel='noreferrer' className={s.guideLink}>la lista de FCMs</a>)",
        automateStep3: 'Fondee su cuenta de trading en FCM',
        automateStep4:
            'Suscríbase a los datos del mercado de Rithmic (recomendamos CME Bundle por defecto)',
        automateStep5:
            'Obtenga un inicio de sesión y contraseña (demo o en vivo) y conéctelos en la página de trading automatizado de RealColibri',
        automateStep6: 'Seleccione un símbolo y presione INICIAR',
        exchangeTitle: 'INTERCAMBIAR TRX POR RCBC',
        exchangeStep1: 'Cree una Cartera TronLink',
        exchangeStep2: 'Transfiera TRX a ella',
        exchangeStep3: 'Intercambie TRX por RCBC usando este formulario',
        exchangeStep4:
            'Recargue el depósito de comisión en su cuenta de RealColibri',
        exchangeInfo:
            'Intercambie TRX por RCBC para pagar una comisión por usar el algoritmo.',
        refreshInfo:
            'Actualice la página después de la instalación. Se le pedirá que conecte su cartera al sitio web.',
        contactUs:
            'No dude en contactarnos si tiene alguna pregunta o necesita información adicional:',
        contactEmail: 'mail@realcolibri.com',
    },
    hy: {
        globalMarkets: 'ՀԱՄԱՇԽԱՐՀԱՅԻՆ ՇՈՒԿԱՆԵՐ',
        ourFeatures: 'Մեր առանձնահատկությունները՝',
        feature1:
            'Կապ Rithmic տվյալների հոսքի միջոցով՝ 19 գլոբալ բորսաների հետ, ներառյալ CME, EUREX, COMEX, NYMEX, SGX, ICE և այլն:',
        feature2:
            "Ցանկացած FCM հաճախորդ, թե նոր բացված, թե գործող հաշիվ ունեցող, կարող է արագ և հեշտությամբ միացնել իր առևտրային հաշիվը RealColibri-ին: <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Հետևեք այս հղմանը՝ տեսնելու FCM-ների ցանկը (cftc.gov):</a>",
        feature3:
            'Բոլոր շահույթները մնում են ձեր առևտրային հաշվում: Մենք վերցնում ենք մեր միջնորդավճարը ալգորիթմի օգտագործման համար կամ մեր RCBC թոքենով (որը մենք խորհուրդ ենք տալիս), կամ անհատական պայմանագրային պայմաններով: Կապվեք մեզ հետ՝ մանրամասները քննարկելու համար:',
        feature4:
            'Միայն դուք եք կառավարում ձեր առևտրային հաշիվը և ալգորիթմի աշխատանքը:',
        feature5:
            'Առևտրային պարամետրերը սահմանված են ռիսկերը նվազագույնի հասցնելու համար՝ կայուն և անվտանգ առևտրի համար:',
        feature6: 'Ավելի քան 1,000 գործարք օրական:',
        chooseExchange: 'Ընտրեք ձեր բորսան',
        requirementsTitle: 'Պահանջներ՝ սկսելու համար՝',
        requirement1: 'RealColibri-ի հաշիվ',
        requirement2: 'TronLink դրամապանակ',
        requirement3: 'Առևտրային հաշիվ FCM-ում',
        requirement4:
            'Rithmic կանոնների համաձայն՝ գաղտնաբառ ստանալու համար անհրաժեշտ է ունենալ նվազագույնը 10,000 ԱՄՆ դոլար դեպոզիտում:',
        requirement5:
            'Մինիմալ դեպոզիտ՝ կենդանի ավտոմատ առևտուրը սկսելու համար՝ 50,000 ԱՄՆ դոլար',
        requirement6Mesu3:
            'Micro E-mini S&P500 (MES)-ի համար նվազագույնը 250,000 ԱՄՆ դոլար, առավելագույնը 100,000,000 ԱՄՆ դոլար:',
        requirement6Esu3:
            'E-mini S&P500 (ES)-ի համար նվազագույնը 2,500,000 ԱՄՆ դոլար, առավելագույնը 1,000,000,000 ԱՄՆ դոլար:',
        automateTitle: 'Ինչպես ավտոմատացնել ձեր առևտուրը՝',
        automateStep1: 'Գրանցվեք RealColibri-ում',
        automateStep2:
            "Բացեք հաշիվ FCM-ում, որը աջակցում է Rithmic տվյալների հոսքը (<a href=\"https://www.cftc.gov/MarketReports/financialfcmdata/index.htm\" target='_blank' rel='noreferrer' className={s.guideLink}>FCM-ների ցանկ</a>)",
        automateStep3: 'Լրացրեք ձեր առևտրային հաշիվը FCM-ում',
        automateStep4:
            'Բաժանորդագրվեք Rithmic շուկայական տվյալներին (առաջարկում ենք նախնական CME փաթեթը)',
        automateStep5:
            'Ստացեք մուտք և գաղտնաբառ (դեմո կամ կենդանի) և միացրեք դրանք RealColibri-ի ավտոմատ առևտրի էջում',
        automateStep6: 'Ընտրեք սիմվոլը և սեղմեք ՍԿՍԵԼ',
        exchangeTitle: 'ՓՈԽԱՐԿԵՔ TRX RCBC-Ի ԴԵՄ',
        exchangeStep1: 'Ստեղծեք TronLink դրամապանակ',
        exchangeStep2: 'Փոխանցեք TRX դրան',
        exchangeStep3: 'Փոխարկեք TRX-ը RCBC-ի դեմ՝ օգտագործելով այս ձևը',
        exchangeStep4:
            'Լրացրեք միջնորդավճարի դեպոզիտը ձեր RealColibri-ի հաշվին',
        exchangeInfo:
            'Փոխարկեք TRX-ը RCBC-ի դեմ՝ ալգորիթմի օգտագործման միջնորդավճար վճարելու համար:',
        refreshInfo:
            'Թարմացրեք էջը տեղադրումից հետո: Ձեզ կառաջարկվի միացնել ձեր դրամապանակը կայքին:',
        contactUs:
            'Խնդրում ենք կապվել մեզ հետ, եթե ունեք հարցեր կամ լրացուցիչ տեղեկություններ անհրաժեշտ են՝',
        contactEmail: 'mail@realcolibri.com',
    },
    fr: {
        globalMarkets: 'MARCHÉS MONDIAUX',
        ourFeatures: 'Nos caractéristiques :',
        feature1:
            'Connexion via le flux de données Rithmic à 19 bourses mondiales, y compris CME, EUREX, COMEX, NYMEX, SGX, ICE, etc.',
        feature2:
            "Tout client FCM, avec un compte nouvellement ouvert ou existant, peut rapidement et facilement connecter son compte de trading à RealColibri. <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Suivez ce lien pour voir la liste des FCMs (cftc.gov).</a>",
        feature3:
            "Tous les profits restent sur votre compte de trading. Nous prélevons notre commission pour l'utilisation de l'algorithme soit en notre token RCBC (que nous recommandons), soit selon des termes contractuels individuels. Contactez-nous pour discuter des détails.",
        feature4:
            "Vous seul contrôlez votre compte de trading et le fonctionnement de l'algorithme.",
        feature5:
            "Les paramètres de trading sont définis pour minimiser les risques afin d'assurer un trading stable et sécurisé.",
        feature6: 'Plus de 1 000 transactions par jour.',
        chooseExchange: 'Choisissez votre bourse',
        requirementsTitle: 'Exigences pour commencer :',
        requirement1: 'Compte RealColibri',
        requirement2: 'Portefeuille TronLink',
        requirement3: 'Compte de trading chez un FCM',
        requirement4:
            'Selon les règles de Rithmic, pour obtenir un mot de passe, un dépôt minimum de 10,000 USD est requis.',
        requirement5:
            'Dépôt minimum pour démarrer le trading automatique en direct : 50,000 USD',
        requirement6Mesu3:
            'Pour Micro E-mini S&P500 (MES) : minimum 250,000 USD, maximum 100,000,000 USD.',
        requirement6Esu3:
            'Pour E-mini S&P500 (ES) : minimum 2,500,000 USD, maximum 1,000,000,000 USD.',
        automateTitle: 'Comment automatiser votre trading :',
        automateStep1: 'Inscrivez-vous sur RealColibri',
        automateStep2:
            "Ouvrez un compte chez un FCM qui prend en charge le flux de données Rithmic (<a href=\"https://www.cftc.gov/MarketReports/financialfcmdata/index.htm\" target='_blank' rel='noreferrer' className={s.guideLink}>la liste des FCMs</a>)",
        automateStep3: 'Alimentez votre compte de trading FCM',
        automateStep4:
            'Abonnez-vous aux données de marché Rithmic (nous recommandons par défaut le CME Bundle)',
        automateStep5:
            'Obtenez un identifiant et un mot de passe (démonstration ou en direct) et connectez-les sur la page de trading automatisé de RealColibri',
        automateStep6: 'Sélectionnez un symbole et appuyez sur DÉMARRER',
        exchangeTitle: 'ÉCHANGEZ TRX CONTRE RCBC',
        exchangeStep1: 'Créez un portefeuille TronLink',
        exchangeStep2: 'Transférez TRX dessus',
        exchangeStep3: 'Échangez TRX contre RCBC en utilisant ce formulaire',
        exchangeStep4:
            'Alimentez le dépôt de commission dans votre compte RealColibri',
        exchangeInfo:
            "Échangez TRX contre RCBC pour payer une commission pour l'utilisation de l'algorithme.",
        refreshInfo:
            "Actualisez la page après l'installation. Vous serez invité à connecter votre portefeuille au site Web.",
        contactUs:
            "Contactez-nous si vous avez des questions ou besoin d'informations supplémentaires :",
        contactEmail: 'mail@realcolibri.com',
    },
    de: {
        globalMarkets: 'WELTMÄRKTE',
        ourFeatures: 'Unsere Merkmale:',
        feature1:
            'Verbindung über Rithmic-Datenfeed zu 19 globalen Börsen, darunter CME, EUREX, COMEX, NYMEX, SGX, ICE usw.',
        feature2:
            "Jeder FCM-Kunde, mit einem neu eröffneten oder bestehenden Konto, kann sein Handelskonto schnell und einfach mit RealColibri verbinden. <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Folgen Sie diesem Link, um die Liste der FCMs zu sehen (cftc.gov).</a>",
        feature3:
            'Alle Gewinne verbleiben auf Ihrem Handelskonto. Wir erheben unsere Provision für die Nutzung des Algorithmus entweder in unserem RCBC-Token (den wir empfehlen) oder nach individuellen vertraglichen Bedingungen. Kontaktieren Sie uns, um die Details zu besprechen.',
        feature4:
            'Nur Sie kontrollieren Ihr Handelskonto und den Betrieb des Algorithmus.',
        feature5:
            'Die Handelsparameter sind so eingestellt, dass Risiken für einen stabilen und sicheren Handel minimiert werden.',
        feature6: 'Mehr als 1.000 Trades pro Tag.',
        chooseExchange: 'Wählen Sie Ihre Börse',
        requirementsTitle: 'Voraussetzungen für den Einstieg:',
        requirement1: 'RealColibri-Konto',
        requirement2: 'TronLink-Wallet',
        requirement3: 'Handelskonto bei einem FCM',
        requirement4:
            'Gemäß den Rithmic-Regeln ist zur Erlangung eines Passworts eine Mindesteinlage von 10,000 USD erforderlich.',
        requirement5:
            'Mindestkapital für den Start des automatischen Echtzeithandels: 50,000 USD',
        requirement6Mesu3:
            'Für Micro E-mini S&P500 (MES): mindestens 250,000 USD, maximal 100,000,000 USD.',
        requirement6Esu3:
            'Für E-mini S&P500 (ES): mindestens 2,500,000 USD, maximal 1,000,000,000 USD.',
        automateTitle: 'So automatisieren Sie Ihren Handel:',
        automateStep1: 'Registrieren Sie sich bei RealColibri',
        automateStep2:
            "Eröffnen Sie ein Konto bei einem FCM, der den Rithmic-Datenfeed unterstützt (<a href=\"https://www.cftc.gov/MarketReports/financialfcmdata/index.htm\" target='_blank' rel='noreferrer' className={s.guideLink}>die Liste der FCMs</a>)",
        automateStep3: 'Finanzieren Sie Ihr FCM-Handelskonto',
        automateStep4:
            'Abonnieren Sie Rithmic-Marktdaten (wir empfehlen standardmäßig das CME-Bundle)',
        automateStep5:
            'Erhalten Sie einen Login und ein Passwort (Demo oder Live) und verbinden Sie diese auf der RealColibri-Automatisierungsseite',
        automateStep6: 'Wählen Sie ein Symbol und drücken Sie START',
        exchangeTitle: 'TAUSCHEN SIE TRX GEGEN RCBC',
        exchangeStep1: 'Erstellen Sie ein TronLink-Wallet',
        exchangeStep2: 'Überweisen Sie TRX darauf',
        exchangeStep3: 'Tauschen Sie TRX mit diesem Formular gegen RCBC',
        exchangeStep4:
            'Laden Sie die Kommissionskaution auf Ihrem RealColibri-Konto auf',
        exchangeInfo:
            'Tauschen Sie TRX gegen RCBC, um eine Provision für die Nutzung des Algorithmus zu zahlen.',
        refreshInfo:
            'Aktualisieren Sie die Seite nach der Installation. Sie werden aufgefordert, Ihr Wallet mit der Website zu verbinden.',
        contactUs:
            'Kontaktieren Sie uns gerne, wenn Sie Fragen haben oder zusätzliche Informationen benötigen:',
        contactEmail: 'mail@realcolibri.com',
    },
    it: {
        globalMarkets: 'MERCATI GLOBALI',
        ourFeatures: 'Le nostre caratteristiche:',
        feature1:
            'Connessione tramite feed dati Rithmic a 19 borse globali, tra cui CME, EUREX, COMEX, NYMEX, SGX, ICE, ecc.',
        feature2:
            "Qualsiasi cliente FCM, con un conto appena aperto o esistente, può collegare rapidamente e facilmente il proprio conto di trading a RealColibri. <a href='https://www.cftc.gov/MarketReports/financialfcmdata/index.htm' target='_blank' rel='noreferrer' className={s.guideLink}>Segui questo link per vedere l'elenco degli FCM (cftc.gov).</a>",
        feature3:
            "Tutti i profitti rimangono nel tuo conto di trading. Addebitiamo la nostra commissione per l'uso dell'algoritmo in RCBC token (che raccomandiamo) o in base a termini contrattuali individuali. Contattaci per discutere i dettagli.",
        feature4:
            "Solo tu controlli il tuo conto di trading e il funzionamento dell'algoritmo.",
        feature5:
            'I parametri di trading sono impostati per ridurre al minimo i rischi per un trading stabile e sicuro.',
        feature6: 'Oltre 1.000 operazioni al giorno.',
        chooseExchange: 'Scegli il tuo exchange',
        requirementsTitle: 'Requisiti per iniziare:',
        requirement1: 'Account RealColibri',
        requirement2: 'Portafoglio TronLink',
        requirement3: 'Conto di trading presso un FCM',
        requirement4:
            'Secondo le regole Rithmic, per ottenere una password è richiesto un deposito minimo di 10.000 USD.',
        requirement5:
            'Deposito minimo per trading automatico in tempo reale: 50.000 USD',
        requirement6Mesu3:
            'Per Micro E-mini S&P500 (MES): minimo 250.000 USD, massimo 100.000.000 USD.',
        requirement6Esu3:
            'Per E-mini S&P500 (ES): minimo 2.500.000 USD, massimo 1.000.000.000 USD.',
        automateTitle: 'Come automatizzare il tuo trading:',
        automateStep1: 'Registrati su RealColibri',
        automateStep2:
            'Apri un conto presso un FCM che supporta il feed dati Rithmic (<a href="https://www.cftc.gov/MarketReports/financialfcmdata/index.htm" target="_blank" rel="noreferrer" className={s.guideLink}>l\'elenco degli FCM</a>)',
        automateStep3: 'Finanzia il tuo conto di trading FCM',
        automateStep4:
            'Abbonati ai dati di mercato Rithmic (raccomandiamo il CME Bundle per impostazione predefinita)',
        automateStep5:
            'Ottieni un login e una password (demo o live) e collegali nella pagina di Trading Automatico di RealColibri',
        automateStep6: 'Seleziona un simbolo e premi INIZIA',
        exchangeTitle: 'CAMBIA TRX IN RCBC',
        exchangeStep1: 'Crea un portafoglio TronLink',
        exchangeStep2: 'Trasferisci TRX su di esso',
        exchangeStep3: 'Scambia TRX per RCBC usando questo modulo',
        exchangeStep4:
            'Ricarica il deposito commissioni nel tuo account RealColibri',
        exchangeInfo:
            "Scambia TRX per RCBC per pagare una commissione per l'utilizzo dell'algoritmo.",
        refreshInfo:
            "Aggiorna la pagina dopo l'installazione. Ti verrà chiesto di collegare il tuo portafoglio al sito.",
        contactUs:
            'Non esitare a contattarci se hai domande o hai bisogno di ulteriori informazioni:',
        contactEmail: 'mail@realcolibri.com',
    },
}

export default translations
