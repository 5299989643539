import { FnActionAsyncProps, FnActionRequiredProps } from 'App.types'
import { SettingProps } from '../RithmicTrade.types'
import { RithmicDataModel } from 'api/http/models'
import { JsonValue } from 'react-use-websocket/dist/lib/types'

export interface TradeTabsProps {
    onSettings: FnActionRequiredProps<SettingProps>
    onSendMessage: FnActionRequiredProps<JsonValue>
    onMarketFetch: FnActionAsyncProps
    symbol?: string
    marketData?: RithmicDataModel
    symbols?: string[]
}

export enum TABS_KEYS {
    COLIBRI = 'COLIBRI',
    MANUAL = 'MANUAL',
}
