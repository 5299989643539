import React, { useCallback, useContext } from 'react'
import { PositionsProps } from './Positions.types'
import { TableAdapter } from 'shared/components'
import { BinanceNativeService } from 'api/http'
import { message } from 'antd'
import { BinanceOrderSideTypes, BinanceOrderTypes } from 'api/http/models'
import { positionsTableColumns } from './Positions.utils'
import { LoaderContext } from 'core/context'

/** Таблица позиций */
export const Positions: React.FC<PositionsProps> = React.memo(
    ({ robotId, marketId, positionsData, symbol }) => {
        const { setLoader } = useContext(LoaderContext)
        /**
         * Обработчик закрытия позиции
         */
        const handleCancelPosition = useCallback(
            async (symbol: string, quantity: number) => {
                try {
                    setLoader(true)
                    if (!marketId) return

                    const { orderId } = await BinanceNativeService.setOrder({
                        market_id: marketId,
                        quantity: Math.abs(quantity),
                        symbol,
                        orderType: BinanceOrderTypes.MARKET,
                        side:
                            quantity >= 0
                                ? BinanceOrderSideTypes.SELL
                                : BinanceOrderSideTypes.BUY,
                        reduceOnly: true,
                    })
                    if (orderId) {
                        message.success('Order submitted')
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, marketId]
        )

        return (
            <TableAdapter
                title={() => <h2>Positions</h2>}
                columns={positionsTableColumns(
                    handleCancelPosition,
                    robotId,
                    symbol
                )}
                loading={!positionsData}
                dataSource={positionsData}
                rowKey="symbol"
                pagination={false}
            />
        )
    }
)
