type Language = 'en' | 'es' | 'ru' | 'hy' | 'de' | 'fr' | 'it'

type Translations = {
    [key in Language]: {
        bannerTitle: string
        bannerText: string
        videoLinks: string[]
    }
}
export const translations: Translations = {
    en: {
        bannerTitle: 'INNOVATIVE AND PRODUCTIVE AUTOMATIC TRADING SYSTEM',
        bannerText:
            'An effective solution to improve automated futures trading',
        videoLinks: [
            'https://www.youtube.com/embed/yvZHsGHLfYs?si=kk1ZUn9QWAGA79Sc',
            'https://www.youtube.com/embed/s7svSzLdEyw',
            'https://www.youtube.com/embed/ELBK94vzhbE?si=faEYWsRjLcECsKgt',
            'https://www.youtube.com/embed/vmecAcrpIZw?si=IO-yqkahseTQMgNc',
        ],
    },
    es: {
        bannerTitle: 'SISTEMA DE COMERCIO AUTOMÁTICO INNOVADOR Y PRODUCTIVO',
        bannerText:
            'Una solución eficaz para mejorar el comercio de futuros automatizado',
        videoLinks: [
            'https://www.youtube.com/embed/wPOycbVriQI?si=hi-0Goh1yWI7NFOU',
            'https://www.youtube.com/embed/6REaGdn2TQs',
            'https://www.youtube.com/embed/_anbLWfMH4U?si=bN_5wcjZ9u8j2Hs3',
            'https://www.youtube.com/embed/2KU83epF5Z8?si=UZmvxy9ylQPviihE',
        ],
    },
    ru: {
        bannerTitle:
            'ИННОВАЦИОННАЯ И ПРОДУКТИВНАЯ АВТОМАТИЧЕСКАЯ ТОРГОВАЯ СИСТЕМА',
        bannerText:
            'Эффективное решение для улучшения автоматизированной фьючерсной торговли',
        videoLinks: [
            'https://www.youtube.com/embed/OYA6HcHwZYM',
            'https://www.youtube.com/embed/WMmyt_lemzU',
            'https://www.youtube.com/embed/0otw6OR-aPw?si=OTFt-pSwlBrHJLzH',
            'https://www.youtube.com/embed/eCO-iFdXDiA?si=4WdC0ylMCEYiXVBl',
        ],
    },
    hy: {
        bannerTitle:
            'ՆՈՐԱՐԱՐԱԿԱՆ ԵՎ ԱՐԴՅՈՒՆԱՎԵՏ ԱՎՏՈՄԱՏԱՑՎԱԾ ԱՌԵՎՏՐԱՅԻՆ ՀԱՄԱԿԱՐԳ',
        bannerText:
            'Արդյունավետ լուծում՝ բարելավելու ավտոմատացված ֆյուչերսային առևտուրը',
        videoLinks: [
            'https://www.youtube.com/embed/inD4zz_SQXY',
            'https://www.youtube.com/embed/RP_65yzdvEg',
            'https://www.youtube.com/embed/jNB877YciUs',
            'https://www.youtube.com/embed/uOR5tPkncXM',
        ],
    },
    de: {
        bannerTitle:
            'INNOVATIVES UND PRODUKTIVES AUTOMATISIERTES HANDELSSYSTEM',
        bannerText:
            'Eine effektive Lösung zur Verbesserung des automatisierten Terminhandels',
        videoLinks: [
            'https://youtube.com/embed/ao6VNdpuQWY',
            'https://www.youtube.com/embed/s6V-6J5qBhY',
            'https://www.youtube.com/embed/hMTmc8n-W1A',
            'https://youtube.com/embed/F32rYFgPjE4',
        ],
    },
    fr: {
        bannerTitle: 'SISTEMA DI TRADING AUTOMATICO INNOVATIVO E PRODUTTIVO',
        bannerText:
            'Une solution efficace pour améliorer le trading automatisé de futures',
        videoLinks: [
            'https://youtube.com/embed/LdDoaNMvYbQ',
            'https://www.youtube.com/embed/A8lbpRDIvHE',
            'https://youtube.com/embed/bFDE301F6NY',
            'https://www.youtube.com/embed/sxI2IEdueoc',
        ],
    },
    it: {
        bannerTitle: 'SISTEMA DI TRADING AUTOMATICO INNOVATIVO E PRODUTTIVO',
        bannerText:
            'Una soluzione efficace per migliorare il trading automatico di futures',
        videoLinks: [
            'https://youtube.com/embed/_ir1u6Gp2Ak',
            'https://www.youtube.com/embed/zK5-5x93oY0',
            'https://youtube.com/embed/eb6MiEFqg04',
            'https://www.youtube.com/embed/pRs3qbWUhA8',
        ],
    },
}

export const getTranslation = (language: string) =>
    translations[language as Language] || translations.en
