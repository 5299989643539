import s from './AutoTrade.module.scss'

import React, { useCallback, useEffect } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl, TextWithRefresh } from 'shared/components'
import { BybitNativeService, BybitService, BybitSymbolsService } from 'api/http'
import { useFetch } from 'shared/hooks'
import { AutoTradeProps } from './AutoTrade.types'
import { BybitStartDto } from 'api/http/models'
import { useWatch } from 'antd/es/form/Form'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ marketData, onMarketFetch }) => {
        const [onStartFetch] = useFetch(BybitService.start)
        const [onStopFetch] = useFetch(BybitService.stop)
        const [fetchSymbols, symbols] = useFetch(
            BybitSymbolsService.getForSelect
        )
        const [fetchMinTokenBalance, minTokenBalance] = useFetch(
            BybitNativeService.getMinTokenBalance
        )
        const [form] = Form.useForm()
        const symbolIdWatch = useWatch('symbolId', form)

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: BybitStartDto) => {
                if (marketData) {
                    if (marketData?.robotId) {
                        await onStopFetch(marketData.id)
                    } else {
                        await onStartFetch(marketData.id, body)
                    }
                    await onMarketFetch()
                }
            },
            [onMarketFetch, marketData]
        )

        const handleMinTokenBalance = useCallback(async () => {
            if (marketData?.id && symbolIdWatch) {
                await fetchMinTokenBalance({
                    symbolId: symbolIdWatch,
                    marketId: marketData.id,
                })
            }
        }, [fetchMinTokenBalance, marketData?.id, symbolIdWatch])

        useEffect(() => {
            fetchSymbols()
        }, [])

        useEffect(() => {
            if (
                symbols?.length &&
                (!form.getFieldValue('symbol') || marketData?.symbolId)
            ) {
                form.setFieldValue(
                    'symbolId',
                    marketData?.symbolId || symbols[0].id
                )
            }
        }, [marketData?.symbolId, symbols])

        useEffect(() => {
            handleMinTokenBalance()
        }, [handleMinTokenBalance])

        return (
            <Form layout="vertical" onFinish={handleFinish} form={form}>
                <div className={s.balance}>
                    <div>
                        {`Min deposit size: ${
                            symbols?.find(el => el.id === symbolIdWatch)
                                ?.minBalance || '-'
                        } USDT`}
                    </div>

                    <TextWithRefresh
                        text={`Min token balance: ${
                            minTokenBalance || '-'
                        } RCBC`}
                        onCallback={handleMinTokenBalance}
                    />
                </div>

                <Row gutter={10}>
                    <Col xs={24} sm={12}>
                        <Form.Item name="symbolId" label="Symbol">
                            <SelectControl
                                disabled={!!marketData?.robotId}
                                options={symbols?.map(el => ({
                                    label: el.name,
                                    value: el.id,
                                    key: el.id,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Button
                            className={s.button}
                            type="primary"
                            htmlType="submit"
                            danger={!!marketData?.robotId}
                            disabled={
                                !symbols?.length ||
                                !marketData ||
                                marketData.isBeStopped
                            }
                        >
                            {marketData?.robotId ? 'STOP' : 'START'}
                        </Button>
                    </Col>
                </Row>
                {marketData?.isBeStopped && (
                    <p>
                        The algorithm waits for the position to be closed in
                        order to stop its work
                    </p>
                )}
            </Form>
        )
    }
)
