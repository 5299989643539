import s from './AutoTrade.module.scss'

import React, { useCallback, useEffect } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl, TextWithRefresh } from 'shared/components'
import { BinanceService, BinanceSymbolsService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { Store } from 'antd/lib/form/interface'
import { useFetch } from 'shared/hooks'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ onTradeSettings, marketData, onMarketFetch, symbolData, symbols }) => {
        const [form] = Form.useForm()
        const [onStartFetch] = useFetch(BinanceService.start)
        const [onStopFetch] = useFetch(BinanceService.stop)
        const [getMinTokenBalance, minTokenBalance] = useFetch(
            BinanceSymbolsService.getMinTokenBalance
        )

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: { symbolId: number }) => {
                if (marketData) {
                    if (marketData?.robotId) {
                        await onStopFetch(marketData.id)
                    } else {
                        await onStartFetch(marketData.id, body)
                    }
                    await onMarketFetch()
                }
            },
            [marketData, onMarketFetch]
        )

        const handleMinTokenBalance = useCallback(async () => {
            if (symbolData?.id) {
                await getMinTokenBalance({ symbol_id: symbolData.id })
            }
        }, [symbolData?.id])

        /**
         * Обработчик изменения значений полей формы
         */
        const handleValuesChange = useCallback(
            (changedValue: Store) => {
                const [key, value] = Object.entries(changedValue)[0]

                if (key === 'symbolId') {
                    onTradeSettings({
                        symbolData: symbols?.find(el => el.id === value),
                    })
                }
            },
            [onTradeSettings, symbols]
        )

        useEffect(() => {
            form.setFieldsValue({
                symbolId:
                    marketData?.symbolId || symbolData?.id || symbols?.[0]?.id,
            })
        }, [form, marketData?.symbolId, symbolData, symbols])

        useEffect(() => {
            if (marketData?.id) {
                handleMinTokenBalance()
            }
        }, [handleMinTokenBalance, marketData?.id])

        return (
            <Form
                onFinish={handleFinish}
                layout="vertical"
                form={form}
                onValuesChange={handleValuesChange}
            >
                <div className={s.balance}>
                    <div>
                        {`Min balance for start: ${
                            symbolData?.min_balance || '-'
                        } USDT`}
                    </div>

                    <TextWithRefresh
                        text={`Min token balance: ${
                            minTokenBalance || '-'
                        } RCBC`}
                        onCallback={handleMinTokenBalance}
                    />
                </div>

                <Row gutter={10}>
                    <Col xs={12}>
                        <Form.Item name="symbolId" label="Symbol">
                            <SelectControl
                                disabled={
                                    !!marketData?.robotId || !symbols?.length
                                }
                                options={symbols?.map(el => ({
                                    value: el.id,
                                    key: el.id,
                                    label: el.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={12}>
                        <Button
                            className={s.button}
                            type="primary"
                            htmlType="submit"
                            danger={!!marketData?.robotId}
                            disabled={
                                !symbols?.length ||
                                !marketData ||
                                marketData.isBeStopped
                            }
                        >
                            {marketData?.robotId ? 'STOP' : 'START'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
)
