import React from 'react'
import s from './VideoDemonstration.module.scss'
import { VideoIFrame } from 'shared/components'
import translations from './translations'

type Props = {
    language: 'en' | 'ru' | 'es' | 'hy' | 'fr' | 'de' | 'it'
}

/** Видео демонстрация */
export const VideoDemonstration: React.FC<Props> = React.memo(
    ({ language }) => {
        const { title, description, videoSrc } =
            translations[language] || translations.en

        return (
            <div className={s.wrapper} id="video">
                <h2 className={s.title}>{title}</h2>

                <div className={s.description}>{description}</div>

                <VideoIFrame className={s.video} src={videoSrc} />
            </div>
        )
    }
)
