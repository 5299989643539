// src/pages/Main/Features/translations.ts
import {
    ApiOutlined,
    IssuesCloseOutlined,
    BarChartOutlined,
    DashboardOutlined,
    RobotOutlined,
    SafetyCertificateOutlined,
} from '@ant-design/icons'

interface FeatureItem {
    icon: React.ComponentType<any>
    title: string
    text: string
}

interface Translations {
    [key: string]: {
        header: string
        features: FeatureItem[]
    }
}

export const translations: Translations = {
    en: {
        header: 'FEATURES',
        features: [
            {
                icon: RobotOutlined,
                title: 'Unique trading system',
                text: 'One-of-a-kind trading algorithm that makes 99% of profitable trades. High-frequency automated trading based on more than 20 years of experience.',
            },
            {
                icon: ApiOutlined,
                title: 'Easy to connect',
                text: 'Any participant of the global futures markets as well as Binance crypto exchange can simply and quickly connect their trading accounts to the RealColibri system - for global markets via login and password, and for crypto exchanges via API keys.',
            },
            {
                icon: DashboardOutlined,
                title: 'Instant result',
                text: 'From the very first trades you save and multiply your funds. Our system allows you to form and increase your capital on a daily and safe basis.',
            },
            {
                icon: BarChartOutlined,
                title: 'High trading volumes',
                text: 'On average, the algorithm makes 1,000 trades per day on one account. All trading parameters are set and accounted for automatically.',
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Minimized risk',
                text: 'Trade parameters and position volume take into account high market volatility and make trading safe. Algorithm settings are designed to minimize possible account drawdowns even during large market movements.',
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Security of funds and data',
                text: 'Only you have access to your account and manage your funds. We do not ask for personal information or access to accounts. You do not transfer funds to management, but activate the algorithm on your account with full control over its operation.',
            },
        ],
    },
    es: {
        header: 'CARACTERÍSTICAS',
        features: [
            {
                icon: RobotOutlined,
                title: 'Sistema de trading único',
                text: 'Algoritmo de trading único que realiza el 99% de las operaciones rentables. Trading automatizado de alta frecuencia basado en más de 20 años de experiencia.',
            },
            {
                icon: ApiOutlined,
                title: 'Fácil de conectar',
                text: 'Cualquier participante de los mercados globales de futuros, así como el intercambio de criptomonedas Binance, puede conectar sus cuentas de trading al sistema RealColibri de manera simple y rápida: para los mercados globales mediante inicio de sesión y contraseña, y para los intercambios de criptomonedas mediante claves API.',
            },
            {
                icon: DashboardOutlined,
                title: 'Resultado instantáneo',
                text: 'Desde los primeros trades usted ahorra y multiplica sus fondos. Nuestro sistema le permite formar e incrementar su capital de manera diaria y segura.',
            },
            {
                icon: BarChartOutlined,
                title: 'Altos volúmenes de trading',
                text: 'En promedio, el algoritmo realiza 1.000 trades por día en una cuenta. Todos los parámetros de trading se establecen y contabilizan automáticamente.',
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Riesgo minimizado',
                text: 'Los parámetros de trading y el volumen de posición tienen en cuenta la alta volatilidad del mercado y hacen que el trading sea seguro. La configuración del algoritmo está diseñada para minimizar posibles reducciones de cuenta incluso durante movimientos grandes del mercado.',
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Seguridad de fondos y datos',
                text: 'Solo usted tiene acceso a su cuenta y maneja sus fondos. No solicitamos información personal ni acceso a cuentas. Usted no transfiere fondos a la gestión, sino que activa el algoritmo en su cuenta con control total sobre su funcionamiento.',
            },
        ],
    },
    ru: {
        header: 'ОСОБЕННОСТИ',
        features: [
            {
                icon: RobotOutlined,
                title: 'Уникальная торговая система',
                text: 'Уникальный торговый алгоритм, который делает 99% прибыльных сделок. Высокочастотная автоматизированная торговля, основанная на более чем 20 лет опыта.',
            },
            {
                icon: ApiOutlined,
                title: 'Простота подключения',
                text: 'Любой участник мировых рынков фьючерсов, а также криптобиржи Binance могут просто и быстро подключить свои торговые счета к системе RealColibri - для мировых рынков через логин и пароль, а для криптобирж через API ключи.',
            },
            {
                icon: DashboardOutlined,
                title: 'Мгновенный результат',
                text: 'С первых сделок вы экономите и увеличиваете свои средства. Наша система позволяет формировать и увеличивать ваш капитал ежедневно и безопасно.',
            },
            {
                icon: BarChartOutlined,
                title: 'Высокие объемы торговли',
                text: 'В среднем алгоритм совершает 1000 сделок в день на одном счете. Все торговые параметры устанавливаются и учитываются автоматически.',
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Минимизированный риск',
                text: 'Торговые параметры и объем позиции учитывают высокую волатильность рынка и делают торговлю безопасной. Настройки алгоритма разработаны для минимизации возможных снижений счета даже во время крупных движений рынка.',
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Безопасность средств и данных',
                text: 'Только вы имеете доступ к своему счету и управляете своими средствами. Мы не запрашиваем персональную информацию или доступ к счетам. Вы не переводите средства на управление, а активируете алгоритм на своем счету с полным контролем над его работой.',
            },
        ],
    },
    hy: {
        header: 'ՀԱՏԿՈՒԹՅՈՒՆՆԵՐ',
        features: [
            {
                icon: RobotOutlined,
                title: 'Ունիկալ առևտրային համակարգ',
                text: 'Ունիկալ առևտրային ալգորիթմ, որը կատարում է 99% շահութաբեր գործարքներ։ Բարձր հաճախականությամբ ավտոմատացված առևտուր, հիմնված ավելի քան 20 տարվա փորձի վրա։',
            },
            {
                icon: ApiOutlined,
                title: 'Հեշտ միացում',
                text: 'Ֆյուչերսների համաշխարհային շուկաների ցանկացած մասնակից, ինչպես նաև Binance կրիպտոբորսան, կարող են պարզապես և արագ միացնել իրենց առևտրային հաշիվները RealColibri համակարգին՝ համաշխարհային շուկաների համար՝ մուտքանունով և գաղտնաբառով, իսկ կրիպտոբորսաների համար՝ API բանալիներով։',
            },
            {
                icon: DashboardOutlined,
                title: 'Ակնթարթային արդյունք',
                text: 'Առաջին գործարքներից դուք խնայում և բազմապատկում եք ձեր միջոցները։ Մեր համակարգը թույլ է տալիս ձևավորել և ավելացնել ձեր կապիտալը ամենօրյա և անվտանգ հիմունքներով։',
            },
            {
                icon: BarChartOutlined,
                title: 'Բարձր առևտրային ծավալներ',
                text: 'Միջինում, ալգորիթմը կատարում է 1000 գործարք մեկ օրվա ընթացքում մեկ հաշվով։ Բոլոր առևտրային պարամետրերը սահմանվում և հաշվարկվում են ավտոմատ կերպով։',
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Նվազեցված ռիսկ',
                text: 'Առևտրային պարամետրերը և դիրքի ծավալը հաշվի են առնում շուկայի բարձր ցնցումները և դարձնում առևտուրը անվտանգ։ Ալգորիթմի կարգավորումները նախագծված են նվազեցնելու հնարավոր հաշվի նվազումները նույնիսկ մեծ շուկայական շարժումների ընթացքում։',
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Միջոցների և տվյալների անվտանգություն',
                text: 'Միայն դուք ունեք մուտք ձեր հաշվին և կառավարում եք ձեր միջոցները։ Մենք չենք հարցնում անձնական տեղեկատվություն կամ հաշիվների մուտք։ Դուք չեք փոխանցում միջոցները կառավարմանը, այլ ակտիվացնում եք ալգորիթմը ձեր հաշվին՝ լիարժեք վերահսկողությամբ նրա աշխատանքի վրա։',
            },
        ],
    },
    de: {
        header: 'FEATURES',
        features: [
            {
                icon: RobotOutlined,
                title: 'Einzigartiges Handelssystem',
                text: 'Einzigartiger Handelsalgorithmus, der 99 % der profitablen Trades ausführt. Hochfrequenter automatisierter Handel basierend auf mehr als 20 Jahren Erfahrung.',
            },
            {
                icon: ApiOutlined,
                title: 'Einfache Verbindung',
                text: 'Jeder Teilnehmer der globalen Futures-Märkte sowie der Binance-Kryptobörse kann seine Handelskonten einfach und schnell mit dem RealColibri-System verbinden – für globale Märkte über Login und Passwort, und für Kryptobörsen über API-Schlüssel.',
            },
            {
                icon: DashboardOutlined,
                title: 'Sofortiges Ergebnis',
                text: 'Schon ab den ersten Trades sparen und vermehren Sie Ihr Kapital. Unser System ermöglicht es Ihnen, Ihr Kapital täglich und sicher zu bilden und zu vermehren.',
            },
            {
                icon: BarChartOutlined,
                title: 'Hohe Handelsvolumen',
                text: 'Im Durchschnitt führt der Algorithmus 1.000 Trades pro Tag auf einem Konto durch. Alle Handelsparameter werden automatisch festgelegt und berücksichtigt.',
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Minimiertes Risiko',
                text: 'Handelsparameter und Positionsvolumen berücksichtigen die hohe Volatilität des Marktes und machen den Handel sicher. Die Einstellungen des Algorithmus sind so ausgelegt, dass mögliche Kontoabfälle selbst bei großen Marktbewegungen minimiert werden.',
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Sicherheit von Geldern und Daten',
                text: 'Nur Sie haben Zugang zu Ihrem Konto und verwalten Ihre Gelder. Wir fragen keine persönlichen Informationen oder Kontozugänge ab. Sie überweisen keine Gelder zur Verwaltung, sondern aktivieren den Algorithmus auf Ihrem Konto und behalten die volle Kontrolle über dessen Betrieb.',
            },
        ],
    },
    fr: {
        header: 'CARACTÉRISTIQUES',
        features: [
            {
                icon: RobotOutlined,
                title: 'Système de trading unique',
                text: "Algorithme de trading unique qui réalise 99 % des transactions rentables. Trading automatisé haute fréquence basé sur plus de 20 ans d'expérience.",
            },
            {
                icon: ApiOutlined,
                title: 'Facile à connecter',
                text: "Tout participant aux marchés à terme mondiaux ainsi qu'à la bourse de crypto Binance peut connecter simplement et rapidement ses comptes de trading au système RealColibri - pour les marchés mondiaux via login et mot de passe, et pour les bourses de crypto via des clés API.",
            },
            {
                icon: DashboardOutlined,
                title: 'Résultat instantané',
                text: "Dès les premiers trades, vous économisez et multipliez vos fonds. Notre système vous permet de former et d'augmenter votre capital de manière quotidienne et sécurisée.",
            },
            {
                icon: BarChartOutlined,
                title: 'Volumes de trading élevés',
                text: "En moyenne, l'algorithme réalise 1 000 trades par jour sur un compte. Tous les paramètres de trading sont définis et pris en compte automatiquement.",
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Risque minimisé',
                text: "Les paramètres de trading et le volume des positions tiennent compte de la forte volatilité du marché et rendent le trading sûr. Les paramètres de l'algorithme sont conçus pour minimiser les baisses potentielles du compte, même lors de grands mouvements de marché.",
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Sécurité des fonds et des données',
                text: "Vous seul avez accès à votre compte et gérez vos fonds. Nous ne demandons pas d'informations personnelles ni d'accès aux comptes. Vous ne transférez pas vos fonds à la gestion, mais activez l'algorithme sur votre compte en gardant le contrôle total sur son fonctionnement.",
            },
        ],
    },
    it: {
        header: 'CARATTERISTICHE',
        features: [
            {
                icon: RobotOutlined,
                title: 'Sistema di trading unico',
                text: 'Algoritmo di trading unico che realizza il 99% delle operazioni profittevoli. Trading automatizzato ad alta frequenza basato su oltre 20 anni di esperienza.',
            },
            {
                icon: ApiOutlined,
                title: 'Facile da connettere',
                text: "Qualsiasi partecipante ai mercati globali di futures, così come all'exchange di criptovalute Binance, può collegare facilmente e rapidamente i propri conti di trading al sistema RealColibri - per i mercati globali tramite login e password, e per le criptovalute tramite chiavi API.",
            },
            {
                icon: DashboardOutlined,
                title: 'Risultato immediato',
                text: 'Dai primi trade risparmi e moltiplichi i tuoi fondi. Il nostro sistema ti consente di formare e incrementare il capitale quotidianamente e in sicurezza.',
            },
            {
                icon: BarChartOutlined,
                title: 'Alti volumi di trading',
                text: "In media, l'algoritmo effettua 1.000 operazioni al giorno su un account. Tutti i parametri di trading sono impostati e calcolati automaticamente.",
            },
            {
                icon: IssuesCloseOutlined,
                title: 'Rischio minimizzato',
                text: "I parametri di trading e il volume delle posizioni tengono conto dell'alta volatilità del mercato e rendono il trading sicuro. Le impostazioni dell'algoritmo sono progettate per minimizzare i possibili cali di conto anche durante grandi movimenti di mercato.",
            },
            {
                icon: SafetyCertificateOutlined,
                title: 'Sicurezza di fondi e dati',
                text: "Solo tu hai accesso al tuo conto e gestisci i tuoi fondi. Non chiediamo informazioni personali né accesso ai conti. Non trasferisci fondi alla gestione, ma attivi l'algoritmo sul tuo conto con pieno controllo sul suo funzionamento.",
            },
        ],
    },
}
