import React, { useCallback, useEffect } from 'react'
import { SelectControl } from 'shared/components'
import { Button, Form } from 'antd'
import { BybitActionsProps } from './BybitActions.types'
import { BybitService } from 'api/http'
import { BybitStartDto } from 'api/http/models'
import { useFetch } from 'shared/hooks'

/** Блок с действиями для таблицы аккаунтов */
export const BybitActions: React.FC<BybitActionsProps> = React.memo(
    ({ record, onFetchAccounts, symbols }) => {
        const [onStartFetch] = useFetch(BybitService.start)
        const [onStopFetch] = useFetch(BybitService.stop)
        const [form] = Form.useForm()

        /** Обработчик формы */
        const handleFinish = useCallback(
            async (body: BybitStartDto) => {
                if (record.robotId) {
                    await onStopFetch(record.id)
                } else {
                    await onStartFetch(record.id, body)
                }

                onFetchAccounts()
            },
            [record, onFetchAccounts]
        )

        useEffect(() => {
            if (
                symbols?.length &&
                (!form.getFieldValue('symbolId') || record?.symbolId)
            ) {
                form.setFieldValue(
                    'symbolId',
                    record?.symbolId || symbols[0].id
                )
            }
        }, [record?.symbolId, symbols])

        return (
            <Form form={form} onFinish={handleFinish}>
                <Form.Item name="symbolId">
                    <SelectControl
                        disabled={!!record.robotId}
                        options={symbols?.map(el => ({
                            label: el.name,
                            value: el.id,
                            key: el.id,
                        }))}
                    />
                </Form.Item>

                <Button
                    block
                    htmlType="submit"
                    type="primary"
                    danger={!!record.robotId || !symbols}
                    disabled={!symbols?.length || record.isBeStopped}
                >
                    {record.robotId ? 'Отключить' : 'Включить'}
                </Button>
            </Form>
        )
    }
)
