import s from './Statistic.module.scss'

import React from 'react'
import { BlockWrapper, StatisticControl } from 'shared/components'
import { Col, Row } from 'antd'
import { PRECISION } from './Statistic.consts'
import { StatisticProps } from './Statistic.types'
import { getStatisticTitle } from './Statistic.utils'
import cn from 'classnames'

/** Статистика торговли на Bybit */
export const Statistic: React.FC<StatisticProps> = React.memo(
    ({ className, statisticSummary, periodStatistic }) => {
        return (
            <BlockWrapper className={cn(s.wrapper, className)}>
                {getStatisticTitle(
                    <h2>Profit statistics (USDT)</h2>,
                    'Profit = PNL - Bybit commission - RealColibri commission'
                )}

                <Row gutter={20}>
                    <Col xs={12}>
                        <StatisticControl
                            title={getStatisticTitle('Today')}
                            value={statisticSummary?.today || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12}>
                        <StatisticControl
                            title={getStatisticTitle('Current month')}
                            value={statisticSummary?.month || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12}>
                        <StatisticControl
                            title={getStatisticTitle('Current year')}
                            value={statisticSummary?.year || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12}>
                        <StatisticControl
                            title="Selected period"
                            value={periodStatistic || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>
                </Row>
            </BlockWrapper>
        )
    }
)
