export const translations = {
    en: {
        title: 'Three simple steps to get started',
        steps: [
            'Create a trading account for global or crypto market',
            'Create a RealColibri account and connect your trading account to RealColibri',
            'Choose a symbol and press start',
        ],
    },
    es: {
        title: 'Tres simples pasos para comenzar',
        steps: [
            'Crea una cuenta de trading para mercado global o criptomonedas',
            'Crea una cuenta en RealColibri y conecta tu cuenta de trading a RealColibri',
            'Elige un símbolo y presiona iniciar',
        ],
    },
    ru: {
        title: 'Три простых шага для начала',
        steps: [
            'Создайте торговый счет для глобального или крипто-рынка',
            'Создайте аккаунт в RealColibri и подключите свой торговый счет к RealColibri',
            'Выберите символ и нажмите старт',
        ],
    },
    hy: {
        title: 'Սկսելու երեք պարզ քայլ',
        steps: [
            'Ստեղծեք առևտրային հաշիվ համաշխարհային կամ կրիպտո շուկայի համար',
            'Ստեղծեք RealColibri հաշիվ և միացրեք ձեր առևտրային հաշիվը RealColibri-ին',
            'Ընտրեք սիմվոլ և սեղմեք սկսել',
        ],
    },
    de: {
        title: 'Drei einfache Schritte, um loszulegen',
        steps: [
            'Erstellen Sie ein Handelskonto für den globalen Markt oder den Kryptomarkt',
            'Erstellen Sie ein RealColibri-Konto und verbinden Sie Ihr Handelskonto mit RealColibri',
            'Wählen Sie ein Symbol und drücken Sie Start',
        ],
    },
    fr: {
        title: 'Trois étapes simples pour commencer',
        steps: [
            'Créez un compte de trading pour le marché mondial ou le marché crypto',
            'Créez un compte RealColibri et connectez votre compte de trading à RealColibri',
            'Choisissez un symbole et appuyez sur démarrer',
        ],
    },
    it: {
        title: 'Tre semplici passi per iniziare',
        steps: [
            'Creare un conto di trading per il mercato globale o per il mercato delle criptovalute',
            'Creare un conto RealColibri e collegare il conto di trading a RealColibri',
            'Scegliere un simbolo e premere start',
        ],
    },
}
