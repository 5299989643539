import React, { createContext, useContext, useState, ReactNode } from 'react'

type Language = 'en' | 'es' | 'ru' | 'hy' | 'fr' | 'de' | 'it'

interface LanguageContextProps {
    language: Language
    setLanguage: (language: Language) => void
}

const LanguageContext = createContext<LanguageContextProps | undefined>(
    undefined
)

export const useLanguage = () => {
    const context = useContext(LanguageContext)
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider')
    }
    return context
}

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [language, setLanguage] = useState<Language>('en')

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}
