import React, { useMemo } from 'react'
import { Col, Row, Tabs } from 'antd'
import { PositionsAndOrdersTabsProps } from './Tables.types'
import { Positions } from './Positions'
import { Orders } from './Orders'
import { DealHistory } from './DealHistory'
import { RobotDeals } from './RobotDeals'

/** Таблицы торговли */
export const Tables: React.FC<PositionsAndOrdersTabsProps> = React.memo(
    ({ robotId, marketId, positionsData, ordersData, symbol }) => {
        const tabItems = useMemo(
            () => [
                {
                    label: `Positions (${positionsData?.length || 0})`,
                    key: 'positions',
                    children: (
                        <Row gutter={40}>
                            <Col xs={24} xxl={12}>
                                <Positions
                                    positionsData={positionsData}
                                    marketId={marketId}
                                    robotId={robotId}
                                    symbol={symbol}
                                />
                            </Col>

                            <Col xs={24} xxl={12}>
                                <Orders
                                    ordersData={ordersData}
                                    marketId={marketId}
                                    robotId={robotId}
                                    symbol={symbol}
                                />
                            </Col>
                        </Row>
                    ),
                },
                {
                    label: 'Trade history',
                    key: 'dealsHistory',
                    children: (
                        <DealHistory marketId={marketId} symbol={symbol} />
                    ),
                },
                {
                    label: 'Robot deals',
                    key: 'robotDeals',
                    children: <RobotDeals />,
                },
            ],
            [marketId, robotId, ordersData, positionsData, symbol]
        )

        return <Tabs defaultActiveKey="positions" items={tabItems} />
    }
)
