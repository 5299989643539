export const translations = {
    en: {
        title: 'LIVE TRADING RESULTS',
        description:
            "Check out the detailed results of the last {count} RealColibri algorithm trades on a randomly selected client's account, including commissions and profits.",
        totalCommission: 'Total market commission:',
        totalProfit: 'Total profit:',
        refreshButton: (timer: number | undefined) =>
            `Refresh ${timer ? ' ' + timer + ' sec.' : 'data'}`,
        allTimeReport: 'FULL REPORT',
        reviewHistory:
            'Review the entire trading history of the algorithm from the very beginning',
        updatesManually: 'Updates every Sunday',
    },
    es: {
        title: 'RESULTADOS DE TRADING EN VIVO',
        description:
            'Consulte los resultados detallados de las últimas {count} operaciones del algoritmo RealColibri en la cuenta de un cliente seleccionado al azar, incluidas comisiones y ganancias.',
        totalCommission: 'Comisión total del mercado:',
        totalProfit: 'Beneficio total:',
        refreshButton: (timer: number | undefined) =>
            `Actualizar ${timer ? ' ' + timer + ' seg.' : 'los datos'}`,
        allTimeReport: 'INFORME COMPLETO',
        reviewHistory:
            'Revise toda la historia comercial del algoritmo desde el principio',
        updatesManually: 'Se actualiza cada domingo',
    },
    ru: {
        title: 'РЕЗУЛЬТАТЫ ТОРГОВЛИ В РЕАЛЬНОМ ВРЕМЕНИ',
        description:
            'Просмотрите детализированные результаты последних {count} сделок алгоритма RealColibri на случайно выбранном клиентском счете, включая комиссии и прибыль.',
        totalCommission: 'Общая комиссия рынка:',
        totalProfit: 'Общая прибыль:',
        refreshButton: (timer: number | undefined) =>
            `Обновить ${timer ? ' ' + timer + ' сек.' : 'данные'}`,
        allTimeReport: 'ПОЛНЫЙ ОТЧЕТ',
        reviewHistory: 'Просмотрите всю историю торговли алгоритма RealColibri',
        updatesManually: 'Обновляется каждое воскресенье',
    },
    hy: {
        title: 'ԱՌԵՎՏՐԻ ԱՐԴՅՈՒՆՔՆԵՐ ԻՐԱԿԱՆ ԺԱՄԱՆԱԿՈՒՄ',
        description:
            'Դիտեք վերջին {count} գործարքների մանրամասն արդյունքները RealColibri ալգորիթմի վրա, ընտրված հաճախորդի հաշվին, ներառյալ միջնորդավճարները և շահույթը։',
        totalCommission: 'Ընդհանուր շուկայական միջնորդավճար:',
        totalProfit: 'Ընդհանուր շահույթ:',
        refreshButton: (timer: number | undefined) =>
            `Թարմացնել ${timer ? ' ' + timer + ' վրկ.' : 'տվյալները'}`,
        allTimeReport: 'Գործարքներ',
        reviewHistory: 'Դիտեք ալգորիթմի ամբողջական առևտրային պատմությունը',
        updatesManually: 'Թարմացվում է ամեն կիրակի',
    },
    de: {
        title: 'LIVE-HANDELSERGEBNISSE',
        description:
            'Sehen Sie sich die detaillierten Ergebnisse der letzten {count} Trades des RealColibri-Algorithmus auf dem Konto eines zufällig ausgewählten Kunden an, einschließlich Provisionen und Gewinne.',
        totalCommission: 'Gesamte Marktkosten:',
        totalProfit: 'Gesamtgewinn:',
        refreshButton: (timer: number | undefined) =>
            `Aktualisieren ${timer ? ' in ' + timer + ' Sek.' : 'Daten'}`,
        allTimeReport: 'GESAMTBERICHT',
        reviewHistory:
            'Überprüfen Sie die gesamte Handelshistorie des Algorithmus von Anfang an',
        updatesManually: 'Wird jeden Sonntag aktualisiert',
    },
    fr: {
        title: 'RÉSULTATS DE TRADING EN DIRECT',
        description:
            "Découvrez les résultats détaillés des dernières {count} transactions de l'algorithme RealColibri sur le compte d'un client sélectionné au hasard, y compris les commissions et les bénéfices.",
        totalCommission: 'Commission totale du marché :',
        totalProfit: 'Profit total :',
        refreshButton: (timer: number | undefined) =>
            `Actualiser ${timer ? ' dans ' + timer + ' sec.' : 'les données'}`,
        allTimeReport: 'RAPPORT COMPLET',
        reviewHistory:
            "Consultez l'historique complet des transactions de l'algorithme depuis le début",
        updatesManually: 'Mis à jour tous les dimanches',
    },
    it: {
        title: 'RISULTATI DI TRADING IN TEMPO REALE',
        description:
            "Guarda i risultati dettagliati delle ultime {count} operazioni dell'algoritmo RealColibri sul conto di un cliente scelto a caso, incluse le commissioni e i profitti.",
        totalCommission: 'Costi totali di mercato:',
        totalProfit: 'Profitto totale:',
        refreshButton: (timer: number | undefined) =>
            `Aggiorna ${timer ? ' tra ' + timer + ' sec.' : 'dati'}`,
        allTimeReport: 'REPORT COMPLETO',
        reviewHistory:
            "Controlla l'intera cronologia delle operazioni dell'algoritmo dall'inizio",
        updatesManually: 'Aggiornato ogni domenica',
    },
}
