import React, { useEffect, useMemo, useState } from 'react'
import { Tabs } from 'antd'
import { TABS_KEYS, TradeTabsProps } from './TradeTabs.types'
import { TradeActions } from './TradeActions'
import { AutoTrade } from './AutoTrade'

/** Блок вкладок для выбора способа торговли */
export const TradeTabs: React.FC<TradeTabsProps> = React.memo(
    ({
        onTradeSettings,
        onMarketFetch,
        marketData,
        positionsData,
        tradeSettings,
        symbols,
    }) => {
        const [tabKey, setTabKey] = useState<string>(TABS_KEYS.COLIBRI)

        /**
         * Обработчик переключения таба
         * @param activeKey ключ таба
         */
        const handleActiveKey = (activeKey: string) => {
            setTabKey(activeKey)
        }

        const tabItems = useMemo(
            () => [
                {
                    label: 'Colibri',
                    key: TABS_KEYS.COLIBRI,
                    children: (
                        <AutoTrade
                            marketData={marketData}
                            onMarketFetch={onMarketFetch}
                            symbolData={tradeSettings.symbolData}
                            onTradeSettings={onTradeSettings}
                            symbols={symbols}
                        />
                    ),
                },
                {
                    label: 'Manual',
                    key: TABS_KEYS.MANUAL,
                    disabled:
                        marketData?.symbolId === tradeSettings.symbolData?.id ||
                        !tradeSettings.symbolData,
                    children: (
                        <TradeActions
                            positionsData={positionsData}
                            tradeSettings={tradeSettings}
                            marketId={marketData?.id}
                        />
                    ),
                },
            ],
            [
                onMarketFetch,
                onTradeSettings,
                positionsData,
                marketData,
                symbols,
                tradeSettings,
            ]
        )

        useEffect(() => {
            if (tradeSettings.symbolData?.id === marketData?.symbolId)
                setTabKey(TABS_KEYS.COLIBRI)
        }, [tradeSettings.symbolData, marketData?.symbolId])

        return (
            <Tabs
                activeKey={tabKey}
                onChange={handleActiveKey}
                items={tabItems}
            />
        )
    }
)
