import React, { useMemo, useState } from 'react'
import { Tabs } from 'antd'
import { TABS_KEYS, TradeTabsProps } from './TradeTabs.types'
import { ManualTrade } from './ManualTrade'
import { AutoTrade } from './AutoTrade'

/** Блок вкладок для выбора способа торговли */
export const TradeTabs: React.FC<TradeTabsProps> = React.memo(
    ({
        symbol,
        onSettings,
        marketData,
        onMarketFetch,
        symbols,
        onSendMessage,
    }) => {
        const [tabKey, setTabKey] = useState<string>(TABS_KEYS.COLIBRI)

        /**
         * Обработчик переключения таба
         * @param activeKey ключ таба
         */
        const handleActiveKey = (activeKey: string) => {
            setTabKey(activeKey)
        }

        const tabItems = useMemo(
            () => [
                {
                    label: 'Colibri',
                    key: TABS_KEYS.COLIBRI,
                    children: (
                        <AutoTrade
                            onMarketFetch={onMarketFetch}
                            onSettings={onSettings}
                            symbol={symbol}
                            marketData={marketData}
                            symbols={symbols}
                        />
                    ),
                },
                {
                    label: 'Manual',
                    key: TABS_KEYS.MANUAL,
                    disabled: !!symbol || !!marketData?.robotId || !marketData,
                    children: !!symbol && (
                        <ManualTrade
                            onSendMessage={onSendMessage}
                            symbol={symbol}
                        />
                    ),
                },
            ],
            [onSettings, marketData, symbols, symbol, onSendMessage]
        )

        return (
            <Tabs
                activeKey={tabKey}
                onChange={handleActiveKey}
                items={tabItems}
            />
        )
    }
)
