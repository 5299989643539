import s from './BinanceStatistic.module.scss'

import React from 'react'
import { BlockWrapper, StatisticControl } from 'shared/components'
import { Col, Row } from 'antd'
import { PRECISION } from './BinanceStatistic.consts'
import { BinanceStatisticProps } from './BinanceStatistic.types'
import { getStatisticTitle } from './BinanceStatistic.utils'
import cn from 'classnames'

/** Статистика торговли на Binance */
export const BinanceStatistic: React.FC<BinanceStatisticProps> = React.memo(
    ({ className, statistic }) => {
        return (
            <BlockWrapper className={cn(s.wrapper, className)}>
                {getStatisticTitle(
                    <h2 className="mb-10">Profit statistics (USDT)</h2>,
                    'Profit = PNL - Binance commission - RealColibri commission'
                )}

                <Row gutter={20}>
                    <Col xs={12}>
                        <StatisticControl
                            title={getStatisticTitle('Today')}
                            value={statistic?.today || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12}>
                        <StatisticControl
                            title={getStatisticTitle('Current month')}
                            value={statistic?.month || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12}>
                        <StatisticControl
                            title={getStatisticTitle('Current year')}
                            value={statistic?.year || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12}>
                        <StatisticControl
                            title="Selected period"
                            value={statistic?.amount || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>
                </Row>
            </BlockWrapper>
        )
    }
)
