import s from './Orders.module.scss'

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { defaultRuleSortingTableNumberColumn, formatDate } from 'shared/utils'
import {
    BinanceOrderSideTypes,
    BinanceOrdersResponseModel,
} from 'api/http/models'
import {
    BINANCE_ORDER_SIDE_TYPES,
    BINANCE_ORDER_TYPES,
} from '../../BinanceTrade.consts'
import { ColorWrapper } from 'shared/components'
import { Button, Tag } from 'antd'

export const ordersTableColumns = (
    onCancelOrder: (id: number, symbol: string) => Promise<void>,
    onCancelAllOrders: () => Promise<void>,
    robotId?: string,
    symbol?: string,
    dataLength?: number
): ColumnsType<BinanceOrdersResponseModel> => [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: formatDate,
        align: 'center',
        sorter: defaultRuleSortingTableNumberColumn('time'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        align: 'center',
    },
    {
        title: 'Type',
        dataIndex: 'origType',
        key: 'origType',
        align: 'center',
        render: (_, record) => BINANCE_ORDER_TYPES[record.origType],
    },
    {
        title: 'Side',
        dataIndex: 'side',
        key: 'side',
        align: 'center',
        render: (_, record) => (
            <ColorWrapper
                text={BINANCE_ORDER_SIDE_TYPES[record.side]}
                condition={record.side === BinanceOrderSideTypes.BUY}
            />
        ),
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        render: (_, record) =>
            record.priceRate
                ? `Rejection price = ${Number(record.priceRate)}%`
                : record.price,
    },
    {
        title: 'Amount',
        dataIndex: 'origQty',
        key: 'origQty',
        align: 'center',
        sorter: defaultRuleSortingTableNumberColumn('origQty'),
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Filled',
        dataIndex: 'executedQty',
        key: 'executedQty',
        align: 'center',
    },
    {
        title: () => (
            <Button
                onClick={onCancelAllOrders}
                disabled={!!robotId || !dataLength}
                className={s.button}
            >
                <Tag>cancel all</Tag>
            </Button>
        ),
        render: record => (
            <Button
                onClick={() => onCancelOrder(record.orderId, record.symbol)}
                disabled={record.symbol === symbol && !!robotId}
                className={s.button}
            >
                <Tag>cancel</Tag>
            </Button>
        ),
        width: 101,
        align: 'center',
    },
]
