import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
    AccountWithMarketsModel,
    BinanceSymbolsForSelectResponseModel,
    WithdrawStatusRequestModel,
} from 'api/http/models'
import { fixedNumber, formatDate } from 'shared/utils'
import { CheckboxControl } from 'shared/components'
import { BinanceActions } from './BinanceActions'
import { FnActionProps, FnActionRequiredProps } from 'App.types'
import { Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { NAV_LINK_NAMES } from 'shared/consts'
import { AccountUpdateProps } from './BinanceAccounts.types'

/** Настройки колонок таблицы аккаунтов */
export const getAccountsTableColumns = (
    onFetchAccounts: FnActionProps,
    onUpdateAccount: FnActionRequiredProps<AccountUpdateProps>,
    onUpdateBinance: (id: number, values: WithdrawStatusRequestModel) => void,
    symbols?: BinanceSymbolsForSelectResponseModel[]
): ColumnsType<AccountWithMarketsModel> => [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
    },
    {
        title: 'Create date',
        dataIndex: 'createDate',
        key: 'createDate',
        render: formatDate,
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        render: (isActive, record) => (
            <CheckboxControl
                checked={isActive}
                onChange={e => {
                    onUpdateAccount({ id: record.id, active: e.target.checked })
                }}
            />
        ),
        align: 'center',
    },
    {
        title: 'Admin',
        dataIndex: 'isAdmin',
        key: 'isAdmin',
        render: (isAdmin, record) => (
            <CheckboxControl
                checked={isAdmin}
                onChange={e => {
                    onUpdateAccount({
                        id: record.id,
                        isAdmin: e.target.checked,
                    })
                }}
            />
        ),
        align: 'center',
    },
    {
        title: 'UserName',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Deposit',
        dataIndex: 'deposit',
        key: 'deposit',
        render: commission => fixedNumber(commission),
    },
    {
        title: 'Locked',
        dataIndex: 'locked',
        key: 'locked',
        render: commission => fixedNumber(commission),
    },
    {
        title: 'Access key',
        key: 'accessKey',
        dataIndex: ['binance', 'accessKey'],
    },
    {
        title: 'Secret key',
        key: 'secretKey',
        dataIndex: ['binance', 'secretKey'],
    },
    {
        title: 'Commission',
        key: 'commission',
        render: (_, record) => (
            <CheckboxControl
                checked={record.binance?.isWithdraw}
                disabled={!record.binance}
                onChange={e => {
                    if (record.binance) {
                        onUpdateBinance(record.binance.id, {
                            isWithdraw: e.target.checked,
                        })
                    }
                }}
            />
        ),
        align: 'center',
    },
    {
        key: 'view',
        render: (_, record) =>
            !!record.binance?.accessKey && (
                <Link to={`${NAV_LINK_NAMES.BINANCE_TRADE}/${record.id}`}>
                    <Button type="link" icon={<EyeOutlined />} />
                </Link>
            ),
        align: 'center',
    },
    {
        title: 'Статус робота',
        key: 'robotStatus',
        render: (_, record) => (
            <BinanceActions
                record={record.binance}
                onFetchAccounts={onFetchAccounts}
                symbols={
                    record.isAdmin
                        ? symbols
                        : symbols?.filter(el => !el.only_admin)
                }
            />
        ),
        width: 120,
    },
]
