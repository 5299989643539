const translations = {
    en: {
        important: 'Important!',
        doNotInterfere:
            'Do not interfere with the algorithm after its launch. Interference in the trading process may lead to undesirable consequences. Do not restart the algorithm if the open position volume is higher than the initial one, do not cancel or set orders on the exchange manually. Even if you know what you are doing, please consult us first on any issue by emailing ',
        responsibility:
            'RealColibri is not responsible for any actions taken on your trading account while the algorithm is running without first consulting us upon written request.',
        algorithmControl: 'Algorithm control',
        optimalDeposit:
            'Optimal deposit size for running the algorithm: x3.75 of the minimum deposit. When the optimal deposit size is reached, the calculation of compound interest is enabled, which will increase the average monthly profit in proportion to the growth of the deposit size. Every 5% of profit increases the position volume by 5%.',
    },
    ru: {
        important: 'Важно!',
        doNotInterfere:
            'Не вмешивайтесь в алгоритм после его запуска. Вмешательство в торговый процесс может привести к нежелательным последствиям. Не перезапускайте алгоритм, если объем открытой позиции превышает начальный, не отменяйте и не устанавливайте ордера на бирже вручную. Даже если вы знаете, что делаете, пожалуйста, сначала проконсультируйтесь с нами по любому вопросу, отправив письмо на почту ',
        responsibility:
            'RealColibri не несет ответственности за любые действия, предпринятые на вашем торговом счете во время работы алгоритма без предварительной консультации с нами по письменному запросу.',
        algorithmControl: 'Управление алгоритмом',
        optimalDeposit:
            'Оптимальный размер депозита для запуска алгоритма: x3,75 от минимального депозита. При достижении оптимального размера депозита включается расчет сложного процента, что увеличит среднюю месячную прибыль пропорционально росту размера депозита. Каждые 5% прибыли увеличивают объем позиции на 5%.',
    },
    es: {
        important: '¡Importante!',
        doNotInterfere:
            'No interfiera con el algoritmo después de su lanzamiento. La interferencia en el proceso de comercio puede llevar a consecuencias indeseables. No reinicie el algoritmo si el volumen de la posición abierta es mayor que el inicial, no cancele ni configure órdenes en el intercambio manualmente. Incluso si sabe lo que está haciendo, consulte con nosotros primero sobre cualquier problema enviando un correo electrónico a ',
        responsibility:
            'RealColibri no es responsable de cualquier acción realizada en su cuenta de trading mientras el algoritmo esté funcionando sin consultarnos primero mediante una solicitud por escrito.',
        algorithmControl: 'Control del algoritmo',
        optimalDeposit:
            'Tamaño óptimo del depósito para ejecutar el algoritmo: x3.75 del depósito mínimo. Cuando se alcanza el tamaño óptimo del depósito, se habilita el cálculo de interés compuesto, lo que aumentará la ganancia mensual promedio en proporción al crecimiento del tamaño del depósito. Cada 5% de ganancia aumenta el volumen de la posición en un 5%.',
    },
    hy: {
        important: 'Կարևոր է!',
        doNotInterfere:
            'Մի խանգարեք ալգորիթմի գործարկմանը հետո: Առևտրային գործընթացի մեջ խառնվելը կարող է հանգեցնել անցանկալի հետևանքների: Մի նոր գործարկեք ալգորիթմը, եթե բացված դիրքի ծավալը գերազանցում է սկզբնականը, մի չեղարկեք կամ կարգավորեք պատվերները բորսայում ձեռքով: Նույնիսկ եթե դուք գիտեք, թե ինչ եք անում, խնդրում ենք նախ մեզ հետ խորհրդակցել ցանկացած հարցի վերաբերյալ՝ ուղարկելով նամակ ',
        responsibility:
            'RealColibri-ն պատասխանատվություն չի կրում ձեր առևտրային հաշվի վրա կատարված գործողությունների համար, եթե ալգորիթմը աշխատում է առանց նախօրոք գրավոր դիմումով մեզ հետ խորհրդակցելու:',
        algorithmControl: 'Ալգորիթմի կառավարում',
        optimalDeposit:
            'Ալգորիթմի գործարկման համար օպտիմալ դեպոզիտի չափը՝ նվազագույն դեպոզիտի x3.75: Երբ օպտիմալ դեպոզիտի չափը հասնում է, բարդ տոկոսի հաշվարկը միանում է, ինչը կբարձրացնի ամսական միջին շահույթը համամասնորեն դեպոզիտի չափի աճի հետ: Յուրաքանչյուր 5% շահույթ ավելացնում է դիրքի ծավալը 5%-ով:',
    },
    fr: {
        important: 'Important !',
        doNotInterfere:
            "Ne pas interférer avec l'algorithme après son lancement. Toute interférence dans le processus de trading peut entraîner des conséquences indésirables. Ne redémarrez pas l'algorithme si le volume de la position ouverte est supérieur à celui initial, ne pas annuler ou passer des ordres sur la bourse manuellement. Même si vous savez ce que vous faites, veuillez d'abord nous consulter pour toute question en envoyant un e-mail à ",
        responsibility:
            "RealColibri n'est pas responsable des actions effectuées sur votre compte de trading pendant que l'algorithme est en cours d'exécution sans nous avoir d'abord consulté par demande écrite.",
        algorithmControl: "Contrôle de l'algorithme",
        optimalDeposit:
            "Taille de dépôt optimale pour exécuter l'algorithme : x3,75 du dépôt minimum. Lorsque la taille de dépôt optimale est atteinte, le calcul des intérêts composés est activé, ce qui augmentera le profit mensuel moyen proportionnellement à la croissance de la taille du dépôt. Chaque 5 % de profit augmente le volume de la position de 5 %.",
    },
    de: {
        important: 'Wichtig!',
        doNotInterfere:
            'Interferieren Sie nicht mit dem Algorithmus nach dessen Start. Eine Einmischung in den Handelsprozess kann zu unerwünschten Folgen führen. Starten Sie den Algorithmus nicht neu, wenn das Volumen der offenen Position höher ist als das Anfangsvolumen, stornieren Sie keine Aufträge und setzen Sie keine Aufträge manuell auf der Börse. Selbst wenn Sie wissen, was Sie tun, konsultieren Sie uns bitte zuerst zu jedem Problem, indem Sie eine E-Mail an ',
        responsibility:
            'RealColibri übernimmt keine Verantwortung für Handlungen, die auf Ihrem Handelskonto durchgeführt werden, während der Algorithmus läuft, ohne uns vorher schriftlich zu konsultieren.',
        algorithmControl: 'Algorithmuskontrolle',
        optimalDeposit:
            'Optimale Einzahlungshöhe für den Betrieb des Algorithmus: x3,75 der Mindesteinzahlung. Wenn die optimale Einzahlungshöhe erreicht ist, wird die Berechnung des Zinseszinses aktiviert, was den durchschnittlichen monatlichen Gewinn proportional zum Wachstum der Einzahlungsgröße erhöht. Alle 5 % Gewinn erhöhen das Positionsvolumen um 5 %.',
    },
    it: {
        important: 'Importante!',
        doNotInterfere:
            "Non interferire con l'algoritmo dopo l'avvio. Un'interferenza nel processo di trading può portare a conseguenze indesiderate. Non riavviare l'algoritmo se il volume delle posizioni aperte è superiore al volume iniziale, non annullare gli ordini e non impostare manualmente ordini sull'exchange. Anche se sai cosa stai facendo, ti preghiamo di consultarti prima con noi su qualsiasi problema, inviando un'e-mail a ",
        responsibility:
            "RealColibri non si assume alcuna responsabilità per le azioni svolte sul tuo conto di trading mentre l'algoritmo è in esecuzione, senza averci consultato preventivamente per iscritto.",
        algorithmControl: "Controllo dell'algoritmo",
        optimalDeposit:
            "Importo ottimale del deposito per il funzionamento dell'algoritmo: x3,75 del deposito minimo. Una volta raggiunto l'importo ottimale del deposito, sarà attivata la contabilizzazione degli interessi composti, aumentando il guadagno medio mensile in proporzione alla crescita della dimensione del deposito. Ogni 5% di guadagno aumenta il volume delle posizioni del 5%.",
    },
}

export default translations
