import {
    AccountCreateModel,
    AccountDepositModel,
    AccountModel,
    AccountReferrerDto,
    AccountSearchModel,
    AccountUpdateModel,
    AccountWithMarketsModel,
    BaseSearchResponseModel,
    BinanceCancelOrderRequestModel,
    BinanceCancelOrderResponseModel,
    BinanceCreateModel,
    BinanceDataModel,
    BinanceDealHistoryRequestModel,
    BinanceDealHistoryResponseModel,
    BinanceGetSymbolsForSelectResponseModel,
    BinanceLeverageBracketResponseModel,
    BinanceLeverageResponseModel,
    BinanceMessageResponseModel,
    BinanceSetOrderRequestModel,
    BinanceSetOrderResponseModel,
    BinanceSymbolModel,
    BinanceSymbolsCreateRequestModel,
    BinanceSymbolsDeleteRequestModel,
    BinanceSymbolsForSelectResponseModel,
    BinanceSymbolsGetMinTokenBalanceRequestModel,
    BinanceSymbolsResponseModel,
    BinanceSymbolsUpdateRequestModel,
    BinanceSymbolsUpdateResponseModel,
    BinanceUpdateModel,
    BybitBalanceDto,
    BybitCancelOrderDto,
    BybitChangeLeverageDto,
    BybitCreateDto,
    BybitDataModel,
    BybitDataSearchDto,
    BybitDealDto,
    BybitDealsPeriodStatisticDto,
    BybitDealsSearchDto,
    BybitDealsStatisticSummaryDto,
    BybitGetInstrumentInfoDto,
    BybitGetLeverageDto,
    BybitGetOrdersHistoryDto,
    BybitGetPositionsHistoryDto,
    BybitLeverageDto,
    BybitOrderHistoryDto,
    BybitOrderIdDto,
    BybitPositionHistoryDto,
    BybitRiskLimitsDto,
    BybitSearchDto,
    BybitSendOrderDto,
    BybitStartDto,
    BybitSymbolBaseDto,
    BybitSymbolDto,
    BybitSymbolForSelectDto,
    BybitUpdateDto,
    ChangeRobotParamsModel,
    DealsRequestModel,
    DealsResponseModel,
    FeeResponseModel,
    FeeUpdateRequestModel,
    LiveTradingResponseModel,
    LoginModel,
    MarketDataStartRequestModel,
    MinTokenBalanceDto,
    PeriodStatisticResponseModel,
    ReferrerProfitResponseModel,
    RithmicCreateModel,
    RithmicDataModel,
    RithmicDataSearchDto,
    RithmicStartDto,
    RithmicUpdateDto,
    StakingResponseModel,
    StatisticRequestModel,
    StatisticResponseModel,
    TronNonceBodyModel,
    TronNonceModel,
    TronVerifyModel,
    WithdrawStatusRequestModel,
} from './models'
import { axiosInstance, instance } from './api.utils'
import {
    ACCOUNT,
    AUTH,
    BINANCE,
    BINANCE_DEALS,
    BINANCE_FEE,
    BINANCE_NATIVE,
    BINANCE_REFERRER_PROFIT,
    BINANCE_STAKING,
    BINANCE_SYMBOLS,
    BYBIT,
    BYBIT_DEALS,
    BYBIT_NATIVE,
    BYBIT_SYMBOLS,
    RITHMIC,
} from './uri'

export const AuthService = {
    /** Авторизация */
    login: (data: LoginModel) => {
        const formData = new FormData()
        formData.append('username', data.username)
        formData.append('password', data.password)

        return axiosInstance(instance.post(`/${AUTH}/login`, formData))
    },

    /** Выход из системы */
    logout: () => axiosInstance(instance.delete(`/${AUTH}/logout`)),

    /** Получить nonce */
    getNonce: (data: TronNonceBodyModel) =>
        axiosInstance<TronNonceModel>(instance.post(`/${AUTH}/nonce`, data)),

    /** Верификация подписи tron */
    tronVerify: (data: TronVerifyModel) =>
        axiosInstance(instance.post(`/${AUTH}/tron-verify`, data)),
}

export const AccountService = {
    /** Создать аккаунт */
    create: (data: AccountCreateModel) =>
        axiosInstance<AccountModel>(instance.post(`/${ACCOUNT}`, data)),

    /** Получить текущий аккаунт */
    getMe: () => axiosInstance<AccountModel>(instance.get(`/${ACCOUNT}/me`)),

    /** Получить аккаунт */
    get: (id: number) =>
        axiosInstance<AccountModel>(instance.get(`/${ACCOUNT}/${id}`)),

    /** Изменить аккаунт */
    update: (id: number, data: AccountUpdateModel) =>
        axiosInstance<AccountModel>(instance.put(`/${ACCOUNT}/${id}`, data)),

    /** Получить аккаунты с фильтром */
    search: (skip: number, limit: number, data: AccountSearchModel) =>
        axiosInstance<BaseSearchResponseModel<AccountWithMarketsModel>>(
            instance.post(`/${ACCOUNT}/${skip}/${limit}`, data)
        ),

    /** Получить реферрера */
    getReferrerAddress: (id: number) =>
        axiosInstance<AccountReferrerDto>(
            instance.get(`/${ACCOUNT}/get-referrer-address/${id}`)
        ),

    /** Пополнить счет токенов */
    deposit: (data: AccountDepositModel) =>
        axiosInstance(instance.post(`/${ACCOUNT}/deposit`, data)),

    /** Вывод токенов */
    withdraw: () => axiosInstance(instance.post(`/${ACCOUNT}/withdraw`)),
}

export const BinanceNativeService = {
    /** Получить историю сделок */
    getDealHistory: (data: BinanceDealHistoryRequestModel) =>
        axiosInstance<BinanceDealHistoryResponseModel[]>(
            instance.post(`/${BINANCE_NATIVE}/trade-history`, data)
        ),

    /** Условия кредитного плеча для тикеров */
    getLeverage: (data: BinanceSymbolModel) =>
        axiosInstance<BinanceLeverageBracketResponseModel[]>(
            instance.post(`/${BINANCE_NATIVE}/leverage-bracket`, data)
        ),

    /** Изменить кредитное плечо */
    setLeverage: (data: BinanceSymbolModel) =>
        axiosInstance<BinanceLeverageResponseModel>(
            instance.post(`/${BINANCE_NATIVE}/leverage`, data)
        ),

    /** Новый ордер */
    setOrder: (data: BinanceSetOrderRequestModel) =>
        axiosInstance<BinanceSetOrderResponseModel>(
            instance.post(`/${BINANCE_NATIVE}/set-order`, data)
        ),

    /** Отменить ордер */
    cancelOrder: (data: BinanceCancelOrderRequestModel) =>
        axiosInstance<BinanceCancelOrderResponseModel>(
            instance.post(`/${BINANCE_NATIVE}/cancel-order`, data)
        ),

    /** Отменить все ордера */
    cancelAllOrders: (data: BinanceSymbolModel) =>
        axiosInstance<BinanceMessageResponseModel>(
            instance.post(`/${BINANCE_NATIVE}/cancel-all-orders`, data)
        ),

    /** Информация о символах для селекта */
    getSymbolsForSelect: () =>
        axiosInstance<BinanceGetSymbolsForSelectResponseModel[]>(
            instance.get(`/${BINANCE_NATIVE}/get-symbols-for-select`)
        ),
}

export const BinanceService = {
    /** Добавить ключи */
    create: (data: BinanceCreateModel) =>
        axiosInstance(instance.post(`/${BINANCE}`, data)),

    /** Получить ключи */
    get: (id: number) =>
        axiosInstance<BinanceDataModel>(instance.get(`/${BINANCE}/${id}`)),

    /** Изменить ключи */
    update: (id: number, data: BinanceUpdateModel) =>
        axiosInstance(instance.put(`/${BINANCE}/${id}`, data)),

    /** Удалить ключи */
    delete: (id: number) => axiosInstance(instance.delete(`/${BINANCE}/${id}`)),

    /** Включение робота */
    start: (id: number, data: MarketDataStartRequestModel) =>
        axiosInstance(instance.post(`/${BINANCE}/robot/start/${id}`, data)),

    /** Выключение робота */
    stop: (id: number) =>
        axiosInstance(instance.delete(`/${BINANCE}/robot/stop/${id}`)),

    /** Выключение всех роботов */
    stopAll: () => axiosInstance(instance.delete(`/${BINANCE}/robot/stop-all`)),

    /** Изменить статус сбора комиссии */
    setWithdrawStatus: (id: number, data: WithdrawStatusRequestModel) =>
        axiosInstance(instance.post(`/${BINANCE}/withdraw-status/${id}`, data)),
}

export const BinanceDealsService = {
    /** Получить сделки робота на бирже */
    search: (data: DealsRequestModel, skip?: number, limit?: number) =>
        axiosInstance<BaseSearchResponseModel<DealsResponseModel>>(
            instance.post(`/${BINANCE_DEALS}/${skip}/${limit}`, data)
        ),

    /** Статистика */
    getStatistic: (data: StatisticRequestModel = {}) =>
        axiosInstance<StatisticResponseModel>(
            instance.post(`/${BINANCE_DEALS}/statistic`, data)
        ),

    /** Статистика за выбранный период */
    getPeriodStatistic: (data: DealsRequestModel) =>
        axiosInstance<PeriodStatisticResponseModel>(
            instance.post(`/${BINANCE_DEALS}/period-statistic`, data)
        ),

    /** Данные о последний 10 сделках */
    getLiveTrading: () =>
        axiosInstance<LiveTradingResponseModel[]>(
            instance.post(`/${BINANCE_DEALS}/live-trading`)
        ),

    /** Перевод комиссии на стейкинг контракт */
    profitToStaking: () =>
        axiosInstance(instance.get(`/${BINANCE_DEALS}/profit-to-staking`)),

    /** Объем комиссии доступной для перевода на стейкинг */
    getStakingProfit: () =>
        axiosInstance<number>(instance.get(`/${BINANCE_DEALS}/staking-profit`)),

    /** Перевод комиссии на реферрер контракт */
    profitToReferrer: () =>
        axiosInstance(instance.get(`/${BINANCE_DEALS}/profit-to-referrer`)),

    /** Объем вознаграждения по реферальной программе */
    getReferrerProfit: () =>
        axiosInstance<number>(
            instance.get(`/${BINANCE_DEALS}/referrer-profit`)
        ),
}

export const BinanceStakingService = {
    /** Получить переводы комиссии на стейкинг */
    search: (skip?: number, limit?: number) =>
        axiosInstance<BaseSearchResponseModel<StakingResponseModel>>(
            instance.post(`/${BINANCE_STAKING}/${skip}/${limit}`, {})
        ),
}

export const BinanceReferrerProfitService = {
    /** Получить переводы комиссии реферрерам */
    search: (skip?: number, limit?: number) =>
        axiosInstance<BaseSearchResponseModel<ReferrerProfitResponseModel>>(
            instance.post(`/${BINANCE_REFERRER_PROFIT}/${skip}/${limit}`, {})
        ),
}

export const BinanceFeeService = {
    /** Получить записи о выводе комиссии */
    search: (skip?: number, limit?: number) =>
        axiosInstance<BaseSearchResponseModel<FeeResponseModel>>(
            instance.post(`/${BINANCE_FEE}/${skip}/${limit}`)
        ),

    /** Редактировать информацию о выводе комиссии */
    update: (data: FeeUpdateRequestModel) =>
        axiosInstance(instance.put(`/${BINANCE_FEE}`, data)),
}

export const BinanceSymbolsService = {
    /** Получить список символов */
    search: (skip?: number, limit?: number) =>
        axiosInstance<BaseSearchResponseModel<BinanceSymbolsResponseModel>>(
            instance.post(`/${BINANCE_SYMBOLS}/${skip}/${limit}`)
        ),

    /** Добавить символ */
    create: (data: BinanceSymbolsCreateRequestModel) =>
        axiosInstance(instance.post(`/${BINANCE_SYMBOLS}`, data)),

    /** Изменить символ */
    update: (data: BinanceSymbolsUpdateRequestModel) =>
        axiosInstance<BinanceSymbolsUpdateResponseModel[]>(
            instance.put(`/${BINANCE_SYMBOLS}`, data)
        ),

    /** Удалить символ */
    delete: (data: BinanceSymbolsDeleteRequestModel) =>
        axiosInstance<BinanceSymbolsUpdateResponseModel[]>(
            instance.post(`/${BINANCE_SYMBOLS}/delete`, data)
        ),

    /** Список символов для селекта */
    forSelect: () =>
        axiosInstance<BinanceSymbolsForSelectResponseModel[]>(
            instance.post(`/${BINANCE_SYMBOLS}/for-select`)
        ),

    /** Минимальный баланс токенов */
    getMinTokenBalance: (data: BinanceSymbolsGetMinTokenBalanceRequestModel) =>
        axiosInstance<number>(
            instance.post(`/${BINANCE_SYMBOLS}/min-token-balance`, data)
        ),

    /** Получить параметры для робота */
    getParams: () =>
        axiosInstance<ChangeRobotParamsModel>(
            instance.get(`/${BINANCE_SYMBOLS}/get-params`)
        ),

    /** Изменить параметры для робота */
    changeParams: (data: ChangeRobotParamsModel) =>
        axiosInstance(instance.post(`/${BINANCE_SYMBOLS}/change-params`, data)),
}

export const RithmicService = {
    /** Получить данные маркета */
    getByAccount: (id: number) =>
        axiosInstance<RithmicDataModel>(
            instance.get(`/${RITHMIC}/by-account/${id}`)
        ),

    /** Добавить ключи */
    create: (data: RithmicCreateModel) =>
        axiosInstance(instance.post(`/${RITHMIC}`, data)),

    /** Изменить запись */
    update: (id: number, data: RithmicUpdateDto) =>
        axiosInstance(instance.put(`/${RITHMIC}/${id}`, data)),

    /** Удалить ключи */
    delete: (id: number) => axiosInstance(instance.delete(`/${RITHMIC}/${id}`)),

    /** Получить аккаунты*/
    search: (skip: number, limit: number) =>
        axiosInstance<BaseSearchResponseModel<RithmicDataSearchDto>>(
            instance.post(`/${RITHMIC}/search/${skip}/${limit}`, {})
        ),

    /** Включение робота */
    start: (id: number, data: RithmicStartDto) =>
        axiosInstance(instance.post(`/${RITHMIC}/robot/start/${id}`, data)),

    /** Выключение робота */
    stop: (id: number) =>
        axiosInstance(instance.delete(`/${RITHMIC}/robot/stop/${id}`)),
}

export const BybitService = {
    /** Получить данные маркета */
    getByAccount: (id: number) =>
        axiosInstance<BybitDataModel>(
            instance.get(`/${BYBIT}/by-account/${id}`)
        ),

    /** Добавить ключи */
    create: (data: BybitCreateDto) =>
        axiosInstance(instance.post(`/${BYBIT}`, data)),

    /** Изменить ключи */
    update: (id: number, data: BybitUpdateDto) =>
        axiosInstance(instance.put(`/${BYBIT}/${id}`, data)),

    /** Удалить ключи */
    delete: (id: number) => axiosInstance(instance.delete(`/${BYBIT}/${id}`)),

    /** Получить аккаунты с фильтром*/
    search: (skip: number, limit: number, data: BybitSearchDto) =>
        axiosInstance<BaseSearchResponseModel<BybitDataSearchDto>>(
            instance.post(`/${BYBIT}/search/${skip}/${limit}`, data)
        ),

    /** Включение робота */
    start: (id: number, data: BybitStartDto) =>
        axiosInstance(instance.post(`/${BYBIT}/robot/start/${id}`, data)),

    /** Выключение робота */
    stop: (id: number) =>
        axiosInstance(instance.delete(`/${BYBIT}/robot/stop/${id}`)),
}

export const BybitNativeService = {
    /** Получить баланс */
    balance: (id: number) =>
        axiosInstance<BybitBalanceDto>(
            instance.get(`/${BYBIT_NATIVE}/balance/${id}`)
        ),

    /** Получить лимиты риска */
    riskLimit: (symbol: string) =>
        axiosInstance<BybitRiskLimitsDto>(
            instance.get(`/${BYBIT_NATIVE}/risk-limit/${symbol}`)
        ),

    /** Разместить ордер */
    sendOrder: (id: number, data: BybitSendOrderDto) =>
        axiosInstance<BybitOrderIdDto>(
            instance.post(`/${BYBIT_NATIVE}/send-order/${id}`, data)
        ),

    /** Отменить ордер */
    cancelOrder: (id: number, data: BybitCancelOrderDto) =>
        axiosInstance(
            instance.post(`/${BYBIT_NATIVE}/cancel-order/${id}`, data)
        ),

    /** Отменить все ордера */
    cancelAllOrders: (id: number) =>
        axiosInstance(instance.get(`/${BYBIT_NATIVE}/cancel-all-orders/${id}`)),

    /** Изменить кредитное плечо */
    changeLeverage: (id: number, data: BybitChangeLeverageDto) =>
        axiosInstance(
            instance.post(`/${BYBIT_NATIVE}/change-leverage/${id}`, data)
        ),

    /** Получить кредитное плечо */
    getLeverage: (id: number, data: BybitGetLeverageDto) =>
        axiosInstance<BybitLeverageDto>(
            instance.post(`/${BYBIT_NATIVE}/get-leverage/${id}`, data)
        ),

    /** Получить историю ордеров */
    getOrdersHistory: (id: number, data: BybitGetOrdersHistoryDto) =>
        axiosInstance<BybitOrderHistoryDto[]>(
            instance.post(`/${BYBIT_NATIVE}/get-orders-history/${id}`, data)
        ),

    /** Получить историю позиций */
    getPositionsHistory: (id: number, data: BybitGetPositionsHistoryDto) =>
        axiosInstance<BybitPositionHistoryDto[]>(
            instance.post(`/${BYBIT_NATIVE}/get-positions-history/${id}`, data)
        ),

    /** Получить информацию о символах */
    getInstrumentsInfo: () =>
        axiosInstance<BybitGetInstrumentInfoDto[]>(
            instance.post(`/${BYBIT_NATIVE}/get-instruments-info`)
        ),

    /** Минимальный баланс токенов */
    getMinTokenBalance: (data: MinTokenBalanceDto) =>
        axiosInstance<number>(
            instance.post(`/${BYBIT_NATIVE}/min-token-balance`, data)
        ),
}

export const BybitSymbolsService = {
    /** Добавить символ */
    create: (data: BybitSymbolBaseDto) =>
        axiosInstance(instance.post(`/${BYBIT_SYMBOLS}`, data)),

    /** Изменить символ */
    update: (id: number, data: BybitSymbolBaseDto) =>
        axiosInstance<BinanceSymbolsUpdateResponseModel[]>(
            instance.put(`/${BYBIT_SYMBOLS}/${id}`, data)
        ),

    /** Удалить символ */
    delete: (id: number) =>
        axiosInstance(instance.delete(`/${BYBIT_SYMBOLS}/${id}`)),

    /** Получить список символов */
    search: (skip: number, limit: number) =>
        axiosInstance<BaseSearchResponseModel<BybitSymbolDto>>(
            instance.post(`/${BYBIT_SYMBOLS}/${skip}/${limit}`)
        ),

    /** Получить список символов для селекта */
    getForSelect: () =>
        axiosInstance<BybitSymbolForSelectDto[]>(
            instance.get(`/${BYBIT_SYMBOLS}/for-select`)
        ),
}

export const BybitDealsService = {
    /** Получить список сделок */
    search: (skip: number, limit: number, data: BybitDealsSearchDto) =>
        axiosInstance<BaseSearchResponseModel<BybitDealDto>>(
            instance.post(`/${BYBIT_DEALS}/search/${skip}/${limit}`, data)
        ),

    /** Статистика */
    getStatisticSummary: (accountId: number) =>
        axiosInstance<BybitDealsStatisticSummaryDto>(
            instance.get(`/${BYBIT_DEALS}/summary/${accountId}`)
        ),

    /** Статистика за выбранный период */
    getPeriodStatistic: (
        accountId: number,
        data: BybitDealsPeriodStatisticDto
    ) =>
        axiosInstance<number>(
            instance.post(`/${BYBIT_DEALS}/period/${accountId}`, data)
        ),
}
