type Translations = {
    [key: string]: {
        title: string
        description: string
        videoSrc: string
    }
}

const translations: Translations = {
    en: {
        title: 'Video-demonstration of how RealColibri Algorithm works',
        description:
            'Trading is done on a live account with real money and the trading period is limited in order to reduce the duration of the video',
        videoSrc:
            'https://www.youtube.com/embed/xAgZHlYpDsQ?si=EJDge1l8lrgEzotv',
    },
    ru: {
        title: 'Видеодемонстрация как алгоритм RealColibri работает',
        description:
            'Торговля осуществляется на реальном счете с реальными деньгами, а период торговли ограничен, чтобы уменьшить продолжительность видео',
        videoSrc:
            'https://www.youtube.com/embed/WQA-D5YkM_Q?si=R8fxY2HVNmHXTdzH',
    },
    es: {
        title: 'Demostración en vídeo del funcionamiento del algoritmo RealColibri',
        description:
            'El comercio se realiza en una cuenta real con dinero real y el período de negociación está limitado para reducir la duración del video',
        videoSrc:
            'https://www.youtube.com/embed/1p0qqDK3uj8?si=KVW-xTgBgE4Eug5P',
    },
    hy: {
        title: 'RealColibri ալգորիթմի աշխատանքի վիդեո ցուցադրություն',
        description:
            'Առևտուրը իրականացվում է իրական հաշվով և իրական գումարով, իսկ առևտրի ժամանակահատվածը սահմանափակ է՝ տեսանյութի տևողությունը կրճատելու համար',
        videoSrc: 'https://www.youtube.com/embed/oyEH8rdj7ZY',
    },
    fr: {
        title: "Démonstration vidéo du fonctionnement de l'algorithme RealColibri",
        description:
            "Le trading est effectué sur un compte réel avec de l'argent réel et la période de trading est limitée afin de réduire la durée de la vidéo",
        videoSrc: 'https://www.youtube.com/embed/nD5v81IjxPw',
    },
    de: {
        title: 'Video-Demonstration, wie der RealColibri-Algorithmus funktioniert',
        description:
            'Der Handel erfolgt auf einem Live-Konto mit echtem Geld und die Handelsperiode ist begrenzt, um die Dauer des Videos zu verkürzen',
        videoSrc: 'https://www.youtube.com/embed/CAy5vNJSEMo',
    },
    it: {
        title: "Video-dimostrazione di come funziona l'algoritmo RealColibri",
        description:
            'Il trading viene effettuato su un conto live con denaro reale e il periodo di trading è limitato per ridurre la durata del video.',
        videoSrc: 'https://www.youtube.com/embed/LqE0JtJV6LM',
    },
}

export default translations
