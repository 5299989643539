import s from './BinanceTrade.module.scss'

import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { PageContent } from 'shared/components'
import { Button, Col, notification, Row, Tooltip } from 'antd'
import { TradeSettingProps } from './BinanceTrade.types'
import { BINANCE_URL_WEBSOCKET } from 'api/ws'
import { BarData, ISeriesApi } from 'lightweight-charts'
import { WsDataEventType, WsDataResponseProps } from 'api/ws/models'
import { INITIAL_TRADE_SETTINGS } from './BinanceTrade.consts'
import { LoaderContext } from 'core/context'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { AccountService, BinanceService, BinanceSymbolsService } from 'api/http'
import {
    BinanceAccountModel,
    BinanceOrdersResponseModel,
    BinancePositionsModel,
    BinanceSymbolsForSelectResponseModel,
    BinanceTickersModel,
} from 'api/http/models'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { normalizePositionsData } from './BinanceTrade.utils'
import { TIME_ZONE_OFFSET } from 'shared/consts'
import { MarketBalance } from './MarketBalance'
import { TradeTabs } from './TradeTabs'
import { TradeTickers } from './TradeTickers'
import { TradeDiagram } from './TradeDiagram'
import { Tables } from './Tables'
import { useFetch } from 'shared/hooks'

/** Торговля на Binance */
export const BinanceTrade: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const { id } = useParams<{ id: string }>()
    const accountId = Number(id)
    const navigate = useNavigate()

    const [getAccount, account] = useFetch(AccountService.get)
    const [getMarket, marketData] = useFetch(BinanceService.get)

    const [tradeSettings, setTradeSettings] = useState<TradeSettingProps>(
        INITIAL_TRADE_SETTINGS
    )
    const [tickersData, setTickersData] = useState<BinanceTickersModel[]>()
    const [tickerLastPrice, setTickerLastPrice] = useState<number>()
    const [ordersData, setOrdersData] = useState<BinanceOrdersResponseModel[]>(
        []
    )
    const [positionsData, setPositionsData] = useState<BinancePositionsModel[]>(
        []
    )
    const [exchangeAccountData, setExchangeAccountData] =
        useState<BinanceAccountModel>()
    const [symbols, setSymbols] =
        useState<BinanceSymbolsForSelectResponseModel[]>()

    const didUnmount = useRef(false)
    const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
        useWebSocket<WsDataResponseProps>(BINANCE_URL_WEBSOCKET, {
            shouldReconnect: () => !didUnmount.current,
            reconnectInterval: 10000,
            queryParams: { id_: accountId },
        })

    const handleMarketFetch = useCallback(async () => {
        await getMarket(accountId)
    }, [getMarket, accountId])

    /**
     * Обновление данных для графика и тикеров
     * @param candlestickSeries сеттер для графика
     */
    const updateTradeData = useCallback(
        (candlestickSeries?: ISeriesApi<'Candlestick'>) => {
            if (!lastJsonMessage || !candlestickSeries) return

            const { event_type, payload } = lastJsonMessage

            switch (event_type) {
                case WsDataEventType.Error:
                    notification.error({
                        message: 'Websocket error',
                        description: payload,
                    })
                    setTickersData([])
                    break
                case WsDataEventType.CandlesUpdate:
                    setTickerLastPrice(Number(payload?.[0].close))
                    if (payload.length > 1) {
                        candlestickSeries.setData(
                            payload.map((el: BarData) => ({
                                ...el,
                                time: Number(el.time) + TIME_ZONE_OFFSET,
                            }))
                        )
                    } else {
                        const tick = payload[0]
                        candlestickSeries.update({
                            ...tick,
                            time: tick.time + TIME_ZONE_OFFSET,
                        })
                    }
                    break
                case WsDataEventType.TickersUpdate:
                    setTickersData(payload)
                    setPositionsData(prevState =>
                        normalizePositionsData(payload, prevState)
                    )
                    break
                case WsDataEventType.PositionsUpdate:
                    setPositionsData(payload)
                    break
                case WsDataEventType.AccountUpdate:
                    setExchangeAccountData(payload)
                    break
                case WsDataEventType.OpenOrdersUpdate:
                    setOrdersData(
                        payload?.filter(
                            (el: BinanceOrdersResponseModel) =>
                                el.symbol === tradeSettings.symbolData?.symbol
                        )
                    )
                    break
            }
        },
        [lastJsonMessage, tradeSettings.symbolData]
    )

    const handleChangeTradeSettings = useCallback(
        (value: Partial<TradeSettingProps>) => {
            setTradeSettings(prevState => {
                const newSettings = {
                    symbolData: value.symbolData || prevState.symbolData,
                    period: value.period || prevState.period,
                }

                sendJsonMessage({
                    symbol: newSettings.symbolData?.symbol,
                    period: newSettings.period,
                })

                return newSettings
            })
        },
        [sendJsonMessage]
    )

    useEffect(
        () => () => {
            didUnmount.current = true
        },
        [getWebSocket]
    )

    useEffect(() => {
        getAccount(accountId)
        handleMarketFetch()
    }, [])

    useEffect(() => {
        if (
            account &&
            tickersData &&
            readyState === ReadyState.OPEN &&
            marketData &&
            !symbols
        ) {
            const fetch = async () => {
                try {
                    setLoader(true)

                    const dataSource = await BinanceSymbolsService.forSelect()
                    const symbolsData = account.isAdmin
                        ? dataSource
                        : dataSource.filter(el => !el.only_admin)

                    setSymbols(symbolsData)

                    const symbolData = marketData.robotId
                        ? symbolsData.find(el => el.id === marketData.symbolId)
                        : symbolsData.find(
                              el => el.symbol === tickersData[0]?.symbol
                          )

                    if (symbolData) {
                        handleChangeTradeSettings({ symbolData })
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            }

            fetch()
        }
    }, [
        setLoader,
        tickersData,
        symbols,
        handleChangeTradeSettings,
        readyState,
        account,
        marketData,
    ])

    return (
        <PageContent>
            <Row gutter={20}>
                <Col xs={24} md={8} xl={6}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={24}>
                            {account && (
                                <>
                                    <Button
                                        type="link"
                                        icon={<ArrowLeftOutlined />}
                                        className={s.back}
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                    >
                                        back
                                    </Button>

                                    <div className={s.account}>
                                        <Tooltip
                                            overlayClassName={s.tooltip}
                                            title={account?.username}
                                        >
                                            <h3>{account?.username}</h3>
                                        </Tooltip>

                                        <div>{`ID: ${account?.id}`}</div>
                                    </div>

                                    <hr />
                                </>
                            )}

                            {exchangeAccountData?.availableBalance && (
                                <MarketBalance
                                    availableBalance={Number(
                                        exchangeAccountData.availableBalance
                                    )}
                                />
                            )}

                            {marketData?.id && (
                                <TradeTabs
                                    tradeSettings={tradeSettings}
                                    onTradeSettings={handleChangeTradeSettings}
                                    positionsData={positionsData}
                                    marketData={marketData}
                                    onMarketFetch={handleMarketFetch}
                                    symbols={symbols}
                                />
                            )}
                        </Col>

                        <Col xs={24} sm={12} md={24}>
                            <TradeTickers
                                onTradeSettings={handleChangeTradeSettings}
                                tickersData={tickersData}
                                symbols={symbols}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={16} xl={18}>
                    <TradeDiagram
                        onTradeSettings={handleChangeTradeSettings}
                        onUpdateTradeData={updateTradeData}
                        tradeSettings={tradeSettings}
                        tickerLastPrice={tickerLastPrice}
                        ordersData={ordersData}
                        positionData={positionsData?.find(
                            el => el.symbol === tradeSettings.symbolData?.symbol
                        )}
                    />
                </Col>
            </Row>

            <Tables
                positionsData={positionsData?.filter(el =>
                    Number(el.positionAmt)
                )}
                ordersData={ordersData}
                symbol={tradeSettings.symbolData?.symbol}
                marketId={marketData?.id}
                robotId={marketData?.robotId}
            />
        </PageContent>
    )
})
