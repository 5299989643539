import s from './Positions.module.scss'

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { BinancePositionsModel } from 'api/http/models'
import { ColorWrapper } from 'shared/components'
import { Button, Tag } from 'antd'

export const positionsTableColumns = (
    onCancelOrder: (symbol: string, quantity: number) => Promise<void>,
    robotId?: string,
    symbol?: string
): ColumnsType<BinancePositionsModel> => [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
    },
    {
        title: 'Leverage',
        dataIndex: 'leverage',
        key: 'leverage',
        render: leverage => `${leverage}x`,
        align: 'center',
    },
    {
        title: 'Size',
        dataIndex: 'positionAmt',
        key: 'positionAmt',
        render: (positionAmt, record) => (
            <ColorWrapper
                text={`${positionAmt} ${record.symbol.replace('USDT', '')}`}
                condition={positionAmt >= 0}
            />
        ),
        align: 'center',
    },
    {
        title: 'Entry price',
        dataIndex: 'entryPrice',
        key: 'entryPrice',
        align: 'center',
        render: entryPrice => Number(entryPrice).toFixed(8),
    },
    {
        title: 'Liq. price',
        dataIndex: 'liquidationPrice',
        key: 'liquidationPrice',
        align: 'center',
        render: liquidationPrice =>
            liquidationPrice ? Number(liquidationPrice).toFixed(8) : '-',
    },
    {
        title: 'PNL',
        dataIndex: 'unRealizedProfit',
        key: 'unRealizedProfit',
        align: 'center',
        render: (_, record) => {
            const pnlToValue =
                Number(record.unRealizedProfit) * Number(record.positionAmt)

            return (
                <ColorWrapper
                    text={`${pnlToValue?.toFixed(2)}`}
                    condition={pnlToValue >= 0}
                />
            )
        },
    },
    {
        render: record => (
            <Button
                onClick={() =>
                    onCancelOrder(record.symbol, Number(record.positionAmt))
                }
                disabled={record.symbol === symbol && !!robotId}
                className={s.button}
            >
                <Tag>market</Tag>
            </Button>
        ),
        align: 'center',
    },
]
