import { TradeSettingProps } from '../BinanceTrade.types'
import {
    BinanceDataModel,
    BinancePositionsModel,
    BinanceSymbolsForSelectResponseModel,
} from 'api/http/models'
import { FnActionAsyncProps, FnActionRequiredProps } from 'App.types'

export interface TradeTabsProps {
    tradeSettings: TradeSettingProps
    onTradeSettings: FnActionRequiredProps<Partial<TradeSettingProps>>
    onMarketFetch: FnActionAsyncProps
    positionsData?: BinancePositionsModel[]
    marketData?: BinanceDataModel
    symbols?: BinanceSymbolsForSelectResponseModel[]
}

export enum TABS_KEYS {
    COLIBRI = 'COLIBRI',
    MANUAL = 'MANUAL',
}
