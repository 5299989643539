import s from './Rithmic.module.scss'

import React, { useCallback, useContext, useEffect } from 'react'
import { BlockWrapper, PageContent, TableAdapter } from 'shared/components'
import { Col, Row } from 'antd'
import { AutoTrade } from './AutoTrade'
import { RithmicKeys } from './RithmicKeys'
import { RithmicStatistic } from './RithmicStatistic'
import { DealsRequestModel } from 'api/http/models'
import {
    DEAL_TABLE_COLUMNS,
    QUERY_PARAMS_INITIAL_VALUES,
} from './Rithmic.consts'
import { getTableTitle } from './Rithmic.utils'
import { useFetch, useQueryParams } from 'shared/hooks'
import { RithmicService } from 'api/http'
import { AccountContext } from 'core/context'
import { useLanguage } from 'context/LanguageContext'
import translations from './translations'

/** Торговля робота на Rithmic */
export const Rithmic: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext)
    const [getMarketData, marketData] = useFetch(RithmicService.getByAccount)
    const { paginationOptions, setQueryParams } =
        useQueryParams<DealsRequestModel>(QUERY_PARAMS_INITIAL_VALUES)
    const { language } = useLanguage()

    const handleMarketFetch = useCallback(async () => {
        if (accountData?.id) {
            await getMarketData(accountData.id)
        }
    }, [accountData?.id, getMarketData])

    useEffect(() => {
        handleMarketFetch()
    }, [handleMarketFetch])

    return (
        <PageContent>
            <BlockWrapper className={s.notification}>
                <b className={s.important}>
                    {translations[language].important}
                </b>{' '}
                {translations[language].doNotInterfere}
                <a
                    className={s.notification}
                    href="mailto:mail@realcolibri.com"
                >
                    mail@realcolibri.com
                </a>
                .{translations[language].responsibility}
            </BlockWrapper>

            <Row gutter={15}>
                <Col xs={24} md={16} lg={11}>
                    <BlockWrapper className={s.card}>
                        <h2 className="mb-10">
                            {translations[language].algorithmControl}
                        </h2>

                        <div className="mb-10">
                            {translations[language].optimalDeposit}
                        </div>

                        <AutoTrade
                            marketData={marketData}
                            onMarketFetch={handleMarketFetch}
                        />
                    </BlockWrapper>
                </Col>

                <Col xs={24} md={8} lg={5}>
                    <RithmicKeys
                        className={s.card}
                        marketData={marketData}
                        onMarketFetch={handleMarketFetch}
                    />
                </Col>

                <Col xs={24} md={24} lg={8}>
                    <RithmicStatistic className={s.card} />
                </Col>
            </Row>

            {marketData?.isBeStopped && (
                <BlockWrapper className={s.stoppedInfo}>
                    The algorithm is in standby mode and will turn off after the
                    current position is closed. Please note that all your
                    actions in relation to the current position will be accepted
                    by the Algorithm and taken into account when closing the
                    position, including the size of the commission in RCBC.
                    <br />
                    <br />
                    Do not place new orders, do not increase or decrease the
                    size of a position without an urgent need to do so. If you
                    close a position manually while the Algorithm is in standby
                    mode, the system will still charge the commission in RCBC.
                </BlockWrapper>
            )}

            <TableAdapter
                title={getTableTitle(setQueryParams)}
                columns={DEAL_TABLE_COLUMNS}
                dataSource={[]}
                pagination={{
                    ...paginationOptions,
                    total: 0,
                }}
            />
        </PageContent>
    )
})
