import s from './RithmicTrade.module.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { RITHMIC_URL_WEBSOCKET } from 'api/ws'
import { useNavigate, useParams } from 'react-router-dom'
import { BarData } from 'lightweight-charts'
import { WsDataEventType, WsDataResponseProps } from 'api/ws/models'
import { Button, Col, notification, Row, Tooltip } from 'antd'
import { TIME_ZONE_OFFSET } from 'shared/consts'
import { PageContent } from 'shared/components'
import { SettingProps, WsRequestEventType } from './RithmicTrade.types'
import { INITIAL_TRADE_SETTINGS } from './RithmicTrade.consts'
import {
    RithmicAccountModel,
    RithmicOrdersResponseModel,
    RithmicPositionsModel,
    RithmicTickersModel,
} from 'api/http/models'
import { AccountService, RithmicService } from 'api/http'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { MarketBalance } from './MarketBalance'
import { TradeTabs } from './TradeTabs'
import { TradeTickers } from './TradeTickers'
import { TradeDiagram } from './TradeDiagram'
import { Tables } from './Tables'
import { useFetch } from 'shared/hooks'

/** Торговля на Rithmic */
export const RithmicTrade: React.FC = React.memo(() => {
    const { id } = useParams<{ id: string }>()
    const accountId = Number(id)
    const navigate = useNavigate()

    const [getAccount, account] = useFetch(AccountService.get)
    const [getMarket, market] = useFetch(RithmicService.getByAccount)
    const [settings, setSettings] = useState<SettingProps>(
        INITIAL_TRADE_SETTINGS
    )
    const [candlestickData, setCandlestickData] = useState<BarData[]>()
    const [exchangeAccountData, setExchangeAccountData] =
        useState<RithmicAccountModel>()
    const [tickersData, setTickersData] = useState<RithmicTickersModel[]>()
    const [tickerLastPrice, setTickerLastPrice] = useState<number>()
    const [positionsData, setPositionsData] =
        useState<RithmicPositionsModel[]>()
    const [ordersData, setOrdersData] = useState<RithmicOrdersResponseModel[]>()

    const didUnmount = useRef(false)
    const { sendJsonMessage, lastJsonMessage, getWebSocket } =
        useWebSocket<WsDataResponseProps>(RITHMIC_URL_WEBSOCKET, {
            shouldReconnect: () => !didUnmount.current,
            reconnectInterval: 10000,
            queryParams: { id_: id || account?.id || '' },
        })

    const handleMarketFetch = useCallback(async () => {
        await getMarket(accountId)
    }, [getMarket, accountId])

    /** Отправка данных через вебсокет */
    const handleSendMessage = useCallback(sendJsonMessage, [sendJsonMessage])

    /** Изменение настроек периода и символа графика */
    const handleSettings = useCallback(
        (value: SettingProps) => {
            setSettings(prevState => {
                if (
                    value.symbol === prevState.symbol ||
                    value.period === prevState.period
                )
                    return prevState

                setTickerLastPrice(undefined)

                const newSettings = {
                    symbol: value.symbol || prevState.symbol,
                    period: value.period || prevState.period,
                }

                handleSendMessage({
                    event_type: WsRequestEventType.Settings,
                    payload: newSettings,
                })

                return newSettings
            })
        },
        [handleSendMessage]
    )

    /**
     * Обновление данных для графика и тикеров
     * @param candlestickSeries сеттер для графика
     */
    const updateTradeData = useCallback(
        (values: WsDataResponseProps) => {
            const { event_type, payload } = values

            switch (event_type) {
                case WsDataEventType.Error:
                    notification.error({
                        message: 'Websocket error',
                        description: payload,
                    })
                    setTickerLastPrice(0)
                    setTickersData(prevState => prevState || [])
                    setPositionsData(prevState => prevState || [])
                    setOrdersData(prevState => prevState || [])
                    break
                case WsDataEventType.CandlesUpdate:
                    setTickerLastPrice(Number(payload[0]?.close))
                    setCandlestickData(
                        payload.map((el: BarData) => ({
                            ...el,
                            time: Number(el.time) + TIME_ZONE_OFFSET,
                        }))
                    )
                    break
                case WsDataEventType.TickersUpdate:
                    if (!settings.symbol) {
                        handleSettings({ symbol: payload?.[0].symbol })
                    }
                    setTickersData(payload)
                    break
                case WsDataEventType.AccountUpdate:
                    setExchangeAccountData(payload)
                    break
                case WsDataEventType.PositionsUpdate:
                    setPositionsData(payload)
                    break
                case WsDataEventType.OpenOrdersUpdate:
                    setOrdersData(
                        payload?.filter(
                            (el: RithmicOrdersResponseModel) =>
                                el.symbol === settings.symbol
                        )
                    )
                    break
            }
        },
        [lastJsonMessage, settings.symbol, handleSettings]
    )

    useEffect(
        () => () => {
            didUnmount.current = true
        },
        [getWebSocket]
    )

    useEffect(() => {
        if (accountId) {
            getAccount(accountId)
            handleMarketFetch()
        }
    }, [])

    useEffect(() => {
        if (lastJsonMessage) {
            updateTradeData(lastJsonMessage)
        }
    }, [lastJsonMessage, updateTradeData])

    return (
        <PageContent>
            <Row gutter={20}>
                <Col xs={6}>
                    <Button
                        type="link"
                        icon={<ArrowLeftOutlined />}
                        className={s.back}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        back
                    </Button>

                    <div className={s.account}>
                        <Tooltip
                            overlayClassName={s.tooltip}
                            title={account?.username}
                        >
                            <h3>{account?.username}</h3>
                        </Tooltip>

                        <div>{`ID: ${account?.id}`}</div>
                    </div>

                    <hr />

                    {exchangeAccountData?.balance && (
                        <MarketBalance balance={exchangeAccountData.balance} />
                    )}

                    <TradeTabs
                        onSendMessage={handleSendMessage}
                        symbol={settings.symbol}
                        onSettings={handleSettings}
                        marketData={market}
                        symbols={tickersData?.map(el => el.symbol)}
                        onMarketFetch={handleMarketFetch}
                    />

                    <TradeTickers
                        onSettings={handleSettings}
                        tickersData={tickersData}
                    />
                </Col>

                <Col xs={18}>
                    <TradeDiagram
                        candlestickData={candlestickData}
                        settings={settings}
                        onSettings={handleSettings}
                        tickerLastPrice={tickerLastPrice}
                        orders={ordersData}
                        position={positionsData?.find(
                            el => el.symbol === settings.symbol
                        )}
                    />
                </Col>
            </Row>

            <Tables
                positionsData={positionsData}
                ordersData={ordersData}
                onSendMessage={handleSendMessage}
            />
        </PageContent>
    )
})
